import { navigateToUrl } from "single-spa";
import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import React, { FC, SVGProps } from "react";
import { useStylesListItemRoute } from "../ListItemRouteStyles";
import { useConsoleLayoutContext } from "../../../ConsoleLayout/context/useConsoleLayoutContext";

export interface ISingleRouteItemProps {
  title: string;
  route: string;
  icon: FC<SVGProps<SVGSVGElement>>;
  showLabel: boolean;
  handlerOnClick: () => void;
}

export const SingleRouteItem: FC<ISingleRouteItemProps> = ({
  title,
  route,
  icon: Icon,
  showLabel,
  handlerOnClick,
}: ISingleRouteItemProps) => {
  const classes = useStylesListItemRoute();
  const { activeRoute } = useConsoleLayoutContext();

  return (
    <ListItem
      button
      className={classes.resellerSidebarListItem}
      onClick={() => {
        handlerOnClick();
        navigateToUrl(route);
      }}
    >
      <ListItemIcon className={classes.resellerSidebarListIcon}>
        <Icon
          className={`${
            ["Histórico", "Emisión de Documentos"].includes(title)
              ? classes.resellerEmissionAndHistoricIcon
              : classes.resellerSidebarIcon
          }${activeRoute === route ? " active" : ""}`}
        />
      </ListItemIcon>

      <ListItemText
        className={`${classes.resellerSidebarText}${
          activeRoute === route ? " active" : ""
        }`}
        classes={{ primary: classes.resellerSidebarSpan }}
        hidden={!showLabel}
        primary={title}
      />
    </ListItem>
  );
};

export default SingleRouteItem;
