export const LANGUAGE_CATALOG: Record<string, any> = {
  frequency: {
    none: "Ninguno",
    daily: "Diaria",
    weekly: "Semanal",
    twoWeekly: "Mar-Vie",
    biWeekly: "Quincenal",
    threeWeekly: "Lun-Mie-Vie",
    monthly: "Mensual",
  },
  paymentType: {
    payOut: "Pay - Out",
    payIn: "Pay - In",
  },
  paymentMethod: {
    cash: "Efectivo",
    card: "Tarjeta",
    transfer: "Transferencia",
  },
};
