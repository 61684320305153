import React, { FC, useEffect, useState } from "react";
import {
  BottomNavigation,
  BottomNavigationAction,
  makeStyles,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { useConsoleLayoutContext } from "../context/useConsoleLayoutContext";
import { navigateToUrl } from "single-spa";
import { isArray, orderBy } from "lodash";
import RightMenu from "./RightMenu/RightMenu";
import { IItemMenu } from "../../../infrastructure/interfaces/IKushkiMenu";

const useStyles = makeStyles((theme) => ({
  resellerFooter: {
    height: `${theme.spacing(10)}px !important`,
    width: "100% !important",
    backgroundColor: "#FFFFFF !important",
  },
  resellerFooterNavigation: {
    height: "100% !important",
    color: "#6D7781 !important",
  },
  buttonLabel: {
    paddingLeft: `${theme.spacing(0.5)}px !important`,
  },
  buttonIcon: {
    maxWidth: `${theme.spacing(4)}px !important`,
    maxHeight: `${theme.spacing(4)}px !important`,
    width: `${theme.spacing(4)}px !important`,
    height: "auto !important",
    fill: "currentcolor !important",
    paddingBottom: `${theme.spacing(1)}px !important`,
  },
}));

export const Footer: FC = () => {
  const classes = useStyles();
  const { activeRoute, kushkiMenu } = useConsoleLayoutContext();
  const [value, setValue] = useState<string>("");
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const menuTarget: string = "menuTarget";

  const isSingleRoute = (route: string | IItemMenu[]): boolean =>
    !isArray(route);

  const isSelected = (route: IItemMenu[]): boolean =>
    isArray(route) && mapRoutes(route).includes(value);

  const mapRoutes = (routes: IItemMenu[]): string[] =>
    routes.map(({ route }) => route);

  useEffect(() => {
    setValue(activeRoute);
  }, [activeRoute]);

  return (
    <footer className={classes.resellerFooter}>
      <BottomNavigation
        className={classes.resellerFooterNavigation}
        value={value}
        onChange={(event, newValue) => {
          newValue === menuTarget || isArray(newValue)
            ? setOpenMenu(true)
            : navigateToUrl(newValue);
        }}
        showLabels
      >
        {orderBy(kushkiMenu, "priority", "asc")
          .slice(0, 3)
          .map(({ routes, title, icon: Icon }, index) => (
            <BottomNavigationAction
              key={index}
              classes={{
                label: classes.buttonLabel,
              }}
              value={isSingleRoute(routes) ? routes : menuTarget}
              className={
                isSelected(routes as IItemMenu[]) ? "Mui-selected" : ""
              }
              label={title}
              icon={<Icon className={classes.buttonIcon} />}
            />
          ))}

        <BottomNavigationAction
          classes={{
            label: classes.buttonLabel,
          }}
          className={"Mui-selected"}
          value={menuTarget}
          label="Menú"
          icon={<MenuIcon className={classes.buttonIcon} />}
        />
      </BottomNavigation>

      <RightMenu openMenu={openMenu} onClose={() => setOpenMenu(false)} />
    </footer>
  );
};

export default Footer;
