import React from "react";
import { CardBlueProps, CardBlue } from "../CardBlue/CardBlue";
import { get } from "lodash";
import { LANGUAGE_CATALOG } from "../../../infrastructure/constants/LanguageCatalog";
import { CertificateProps, Certificates } from "./Certificates";
import { CountriesEnum } from "../../../infrastructure/constants/Countries";
import { CatalogCo } from "../../../infrastructure/services/catalog-co";
import { CatalogPe } from "../../../infrastructure/services/catalog-pe";
export interface InformationProps {
  merchantInfo?: any;
}

export const InformationTab: React.FC<InformationProps> = (
  props: InformationProps
) => {
  const { merchantInfo } = props;
  const merchantToProps: CardBlueProps = {
    items: [
      { key: "Title", value: "Datos de facturación", isTitle: true },
      { key: "Nombre", value: merchantInfo.merchantName },
      { key: "Razón social", value: merchantInfo.socialReason },
      { key: "Tax ID", value: merchantInfo.documentNumber },
      { key: "Dirección", value: merchantInfo.address },
      {
        key: "Ciudad",
        value:
          merchantInfo.country == CountriesEnum.COLOMBIA
            ? CatalogCo.sCities.filter(
                (item) => item.value === merchantInfo.city
              )[0].name
            : merchantInfo.country == CountriesEnum.PERU
            ? CatalogPe.sCities.filter(
                (item) => item.value === merchantInfo.city
              )[0].name
            : merchantInfo.city,
      },
      { key: "Title2", value: "Frecuencia de facturación", isTitle: true },
      {
        key: "Facturación",
        value: get(
          LANGUAGE_CATALOG,
          `frequency.${get(merchantInfo, "invoiceFrequency", "").trim()}`,
          ""
        ),
      },
    ],
  };

  const certificates: CertificateProps = {
    title: "Certificados activos",
    items: get(merchantInfo, "certificates", []),
  };

  return (
    <>
      {merchantInfo && <CardBlue {...merchantToProps} />}
      <Certificates {...certificates}></Certificates>
    </>
  );
};
