import React, { FC } from "react";
import { isArray } from "lodash";
import SingleRouteItem from "./SingleRouteItem/SingleRouteItem";
import MultiRouteItem from "./MultiRouteItem/MultiRouteItem";
import {
  IItemMenu,
  IKushkiMenu,
} from "../../../infrastructure/interfaces/IKushkiMenu";

export interface IListItemRoute {
  item: IKushkiMenu;
  showLabel?: boolean;
  handlerSelectRoute?: () => void;
  handlerClickItem?: () => void;
}

export const ListItemRoute: FC<IListItemRoute> = ({
  item: { routes, title, icon },
  showLabel = true,
  handlerClickItem = () => {},
  handlerSelectRoute = () => {},
}: IListItemRoute) => {
  const isSingleRoute: boolean = !isArray(routes);

  return isSingleRoute ? (
    <SingleRouteItem
      title={title}
      icon={icon}
      route={routes as string}
      showLabel={showLabel}
      handlerOnClick={() => {
        handlerClickItem();
        handlerSelectRoute();
      }}
    />
  ) : (
    <MultiRouteItem
      title={title}
      icon={icon}
      routes={routes as IItemMenu[]}
      showLabel={showLabel}
      handlerClickItem={handlerClickItem}
      handlerSelectRoute={handlerSelectRoute}
    />
  );
};

export default ListItemRoute;
