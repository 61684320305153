import { Activity, RegisterApplicationConfig } from "single-spa";
import Preconfig from "../infrastructure/constants/Preconfig";
import { applications } from "../infrastructure/constants/Applications";
import { isEmpty } from "lodash";
import { environment } from "../environments/environment";

interface IAppsTo {
  register: RegisterApplicationConfig;
  importPath: string;
}

const { devSpa, domain, envName } = environment;
const { prefixPath, orgName, suffix } = Preconfig;
const newSuffixPath: string = isEmpty(suffix) ? "" : `-${suffix}`;

const appsTo: IAppsTo[] = applications.map(({ name: spaName, route }) => {
  const newDomain: string =
    envName === "primary" ? domain : `${envName}-${domain}`;
  const newSpaName: string = `${spaName}${newSuffixPath}`;
  const importName: string = `@${orgName}/${prefixPath}-${newSpaName}`;
  const indexPort: number = devSpa
    .replace(/\s/g, "")
    .split(",")
    .indexOf(spaName);
  const spaDomain: string =
    indexPort >= 0
      ? `http://localhost:80${80 + indexPort}`
      : `https://${newDomain}/${newSpaName}`;

  const activeWhen: Activity = route;

  const register: RegisterApplicationConfig = {
    name: importName,
    app: () => (window as any).System.import(importName),
    activeWhen,
  };

  return {
    register,
    importPath: `"${importName}": "${spaDomain}/${orgName}-${prefixPath}-${newSpaName}.js"`,
  };
});

export const appsToRegister: RegisterApplicationConfig[] = appsTo.map(
  ({ register }) => register
);

export const appsToImport: string = appsTo
  .map(({ importPath }) => importPath)
  .join(",");

export const appsToRender: string[] = appsToRegister
  .filter(({ name }) => !name.includes(`${prefixPath}-login`))
  .map(({ name }) => name);

export const allowedRoutes: string[] = applications
  .filter(({ name }) => !name.includes("login"))
  .map(({ route }) => route)
  .flat();

export const suffixPath: string = newSuffixPath;
