import { useEffect, useState } from "react";
import { SnackBarAlertState } from "./useSnackBarAlertState.interfaces";
import { SnackbarAlertProps } from "../SnackBarAlert.interfaces";

export const useSnackBarAlertState = ({
  open,
}: SnackbarAlertProps): SnackBarAlertState => {
  const [openSnackbar, setOpenSnackbar] = useState(open);

  useEffect(() => setOpenSnackbar(open), [open]);

  return { open: openSnackbar };
};
