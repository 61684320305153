import { Box, IconButton, makeStyles, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React, { FC } from "react";
import { useConsoleLayoutContext } from "../../../context/useConsoleLayoutContext";

const useStyles = makeStyles((theme) => ({
  resellerHeaderMenu: {
    padding: `${theme.spacing(1, 1, 1, 4)} !important`,
    backgroundColor: "#F0F4F9 !important",
    height: `${theme.spacing(10)}px !important`,
    width: "100% !important",
    display: "flex !important",
    justifyContent: "space-between !important",
    alignItems: "center !important",
    color: `${theme.palette.primary.main} !important`,
  },
}));

export interface IHeaderMenuProps {
  handlerOnClick: () => void;
}

export const HeaderMenu: FC<IHeaderMenuProps> = ({
  handlerOnClick,
}: IHeaderMenuProps) => {
  const classes = useStyles();
  const { isAdmin } = useConsoleLayoutContext();

  return (
    <Box className={classes.resellerHeaderMenu}>
      <Typography color={"primary"} variant={"h3"}>
        {isAdmin ? "Admin" : "Usuario"}
      </Typography>
      <IconButton onClick={() => handlerOnClick()}>
        <CloseIcon color={"primary"} fontSize={"large"} />
      </IconButton>
    </Box>
  );
};

export default HeaderMenu;
