export enum LocalStorageEnum {
  PAYLOAD = "payload",
  ROLES = "roles",
  IS_ADMIN = "isAdmin",
  EMAIL = "email",
  MERCHANT_ID = "merchantId",
  MERCHANT_BASIC_INFORMATION = "merchantBasicInformation",
  USERNAME = "username",
  FULL_NAME = "fullName",
  TIMEZONE = "timeZone",
  SYSTEM_TIMEZONE = "time-zone",
  MERCHANT_RESUME = "merchantResume",
  TOGGLE_SIDEBAR = "toggleSidebar",
}
