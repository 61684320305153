export enum RoutesEnum {
  DASHBOARD = "/dashboard",
  AUTH = "/auth",
  WALLET = "/wallet",
  TRANSACTIONS = "/documents/transactions",
  MERCHANT = "/merchant",
  BILLING = "/billing",
  USERS = "/users",
  INFORMATION = "/dashboard/information",
  DEVS = "/dashboard/developers",
  MERCHANT_BILLING = "/merchant-billing",
  WEBHOOK = "/webhook",
  CREDENTIALS = "/credentials",
  ROLE_NOT_FOUND = "/role-not-found",
  CREATE_MERCHANT = "/create-merchant-reseller",
  MERCHANTS = "/merchant-list",
  TRANSACTION_RATES = "/transaction-rates",
  COST_CENTER = "/cost-center",
  EMISSION = "/emission",
  OLD_EMISSION = "/emission",
  DOCUMENT = "/document",
}
