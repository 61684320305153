import { SvgIcon, SvgIconProps } from "@material-ui/core";
import React, { FC } from "react";

const CheckIcon: FC<SvgIconProps> = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 19 14" {...props}>
      <svg
        width="19"
        height="14"
        viewBox="0 0 19 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.584 1.39404L6.58398 12.394L1.58398 7.39404"
          stroke="white"
          strokeWidth="1.3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};

export default CheckIcon;
