export interface IPreconfig {
  prefixPath: string;
  orgName: string;
  suffix?: string;
}

const Preconfig: IPreconfig = {
  prefixPath: "spa-console",
  suffix: "reseller",
  orgName: "kushki",
};

export default Preconfig;
