/**
 * Catalog Colombia
 */
import { CatalogsEnum } from "../catalogs/catalogs-enum";
import { Category } from "../constants/Countries";

export const CatalogCo: Record<string, Category[]> = {
  [CatalogsEnum.Cities]: [
    {
      value: "05001",
      name: "MEDELLÍN",
      provinceCode: "05",
    },
    {
      value: "05002",
      name: "ABEJORRAL",
      provinceCode: "05",
    },
    {
      value: "05004",
      name: "ABRIAQUÍ",
      provinceCode: "05",
    },
    {
      value: "05021",
      name: "ALEJANDRÍA",
      provinceCode: "05",
    },
    {
      value: "05030",
      name: "AMAGÁ",
      provinceCode: "05",
    },
    {
      value: "05031",
      name: "AMALFI",
      provinceCode: "05",
    },
    {
      value: "05034",
      name: "ANDES",
      provinceCode: "05",
    },
    {
      value: "05036",
      name: "ANGELÓPOLIS",
      provinceCode: "05",
    },
    {
      value: "05038",
      name: "ANGOSTURA",
      provinceCode: "05",
    },
    {
      value: "05040",
      name: "ANORÍ",
      provinceCode: "05",
    },
    {
      value: "68176",
      name: "CHIMÁ",
      provinceCode: "68",
    },
    {
      value: "05044",
      name: "ANZA",
      provinceCode: "05",
    },
    {
      value: "05045",
      name: "APARTADÓ",
      provinceCode: "05",
    },
    {
      value: "05051",
      name: "ARBOLETES",
      provinceCode: "05",
    },
    {
      value: "05055",
      name: "ARGELIA",
      provinceCode: "05",
    },
    {
      value: "05059",
      name: "ARMENIA",
      provinceCode: "05",
    },
    {
      value: "05079",
      name: "BARBOSA",
      provinceCode: "05",
    },
    {
      value: "05088",
      name: "BELLO",
      provinceCode: "05",
    },
    {
      value: "05091",
      name: "BETANIA",
      provinceCode: "05",
    },
    {
      value: "05093",
      name: "BETULIA",
      provinceCode: "05",
    },
    {
      value: "05101",
      name: "CIUDAD BOLÍVAR",
      provinceCode: "05",
    },
    {
      value: "05107",
      name: "BRICEÑO",
      provinceCode: "05",
    },
    {
      value: "05113",
      name: "BURITICÁ",
      provinceCode: "05",
    },
    {
      value: "05120",
      name: "CÁCERES",
      provinceCode: "05",
    },
    {
      value: "05125",
      name: "CAICEDO",
      provinceCode: "05",
    },
    {
      value: "05129",
      name: "CALDAS",
      provinceCode: "05",
    },
    {
      value: "05134",
      name: "CAMPAMENTO",
      provinceCode: "05",
    },
    {
      value: "05138",
      name: "CAÑASGORDAS",
      provinceCode: "05",
    },
    {
      value: "05142",
      name: "CARACOLÍ",
      provinceCode: "05",
    },
    {
      value: "05145",
      name: "CARAMANTA",
      provinceCode: "05",
    },
    {
      value: "05147",
      name: "CAREPA",
      provinceCode: "05",
    },
    {
      value: "70670",
      name: "SAMPUÉS",
      provinceCode: "70",
    },
    {
      value: "05150",
      name: "CAROLINA",
      provinceCode: "05",
    },
    {
      value: "05154",
      name: "CAUCASIA",
      provinceCode: "05",
    },
    {
      value: "05172",
      name: "CHIGORODÓ",
      provinceCode: "05",
    },
    {
      value: "05190",
      name: "CISNEROS",
      provinceCode: "05",
    },
    {
      value: "05197",
      name: "COCORNÁ",
      provinceCode: "05",
    },
    {
      value: "05206",
      name: "CONCEPCIÓN",
      provinceCode: "05",
    },
    {
      value: "05209",
      name: "CONCORDIA",
      provinceCode: "05",
    },
    {
      value: "05212",
      name: "COPACABANA",
      provinceCode: "05",
    },
    {
      value: "05234",
      name: "DABEIBA",
      provinceCode: "05",
    },
    {
      value: "05237",
      name: "DON MATÍAS",
      provinceCode: "05",
    },
    {
      value: "05240",
      name: "EBÉJICO",
      provinceCode: "05",
    },
    {
      value: "05250",
      name: "EL BAGRE",
      provinceCode: "05",
    },
    {
      value: "05264",
      name: "ENTRERRIOS",
      provinceCode: "05",
    },
    {
      value: "05266",
      name: "ENVIGADO",
      provinceCode: "05",
    },
    {
      value: "05282",
      name: "FREDONIA",
      provinceCode: "05",
    },
    {
      value: "05306",
      name: "GIRALDO",
      provinceCode: "05",
    },
    {
      value: "05308",
      name: "GIRARDOTA",
      provinceCode: "05",
    },
    {
      value: "05310",
      name: "GÓMEZ PLATA",
      provinceCode: "05",
    },
    {
      value: "85225",
      name: "NUNCHÍA",
      provinceCode: "85",
    },
    {
      value: "05315",
      name: "GUADALUPE",
      provinceCode: "05",
    },
    {
      value: "05318",
      name: "GUARNE",
      provinceCode: "05",
    },
    {
      value: "05321",
      name: "GUATAPÉ",
      provinceCode: "05",
    },
    {
      value: "05347",
      name: "HELICONIA",
      provinceCode: "05",
    },
    {
      value: "05353",
      name: "HISPANIA",
      provinceCode: "05",
    },
    {
      value: "05360",
      name: "ITAGUI",
      provinceCode: "05",
    },
    {
      value: "05361",
      name: "ITUANGO",
      provinceCode: "05",
    },
    {
      value: "54518",
      name: "PAMPLONA",
      provinceCode: "54",
    },
    {
      value: "05368",
      name: "JERICÓ",
      provinceCode: "05",
    },
    {
      value: "05376",
      name: "LA CEJA",
      provinceCode: "05",
    },
    {
      value: "05380",
      name: "LA ESTRELLA",
      provinceCode: "05",
    },
    {
      value: "05390",
      name: "LA PINTADA",
      provinceCode: "05",
    },
    {
      value: "05400",
      name: "LA UNIÓN",
      provinceCode: "05",
    },
    {
      value: "05411",
      name: "LIBORINA",
      provinceCode: "05",
    },
    {
      value: "05425",
      name: "MACEO",
      provinceCode: "05",
    },
    {
      value: "05440",
      name: "MARINILLA",
      provinceCode: "05",
    },
    {
      value: "05467",
      name: "MONTEBELLO",
      provinceCode: "05",
    },
    {
      value: "05475",
      name: "MURINDÓ",
      provinceCode: "05",
    },
    {
      value: "05480",
      name: "MUTATÁ",
      provinceCode: "05",
    },
    {
      value: "05483",
      name: "Nariño",
      provinceCode: "05",
    },
    {
      value: "05490",
      name: "NECOCLÍ",
      provinceCode: "05",
    },
    {
      value: "05495",
      name: "NECHÍ",
      provinceCode: "05",
    },
    {
      value: "05501",
      name: "OLAYA",
      provinceCode: "05",
    },
    {
      value: "05541",
      name: "PEÑOL",
      provinceCode: "05",
    },
    {
      value: "05543",
      name: "PEQUE",
      provinceCode: "05",
    },
    {
      value: "05576",
      name: "PUEBLORRICO",
      provinceCode: "05",
    },
    {
      value: "05579",
      name: "PUERTO BERRÍO",
      provinceCode: "05",
    },
    {
      value: "05585",
      name: "PUERTO NARE",
      provinceCode: "05",
    },
    {
      value: "05591",
      name: "PUERTO TRIUNFO",
      provinceCode: "05",
    },
    {
      value: "05604",
      name: "REMEDIOS",
      provinceCode: "05",
    },
    {
      value: "05607",
      name: "RETIRO",
      provinceCode: "05",
    },
    {
      value: "05615",
      name: "RIONEGRO",
      provinceCode: "05",
    },
    {
      value: "05628",
      name: "SABANALARGA",
      provinceCode: "05",
    },
    {
      value: "05631",
      name: "SABANETA",
      provinceCode: "05",
    },
    {
      value: "05642",
      name: "SALGAR",
      provinceCode: "05",
    },
    {
      value: "25019",
      name: "ALBÁN",
      provinceCode: "25",
    },
    {
      value: "97889",
      name: "YAVARATÉ",
      provinceCode: "97",
    },
    {
      value: "05652",
      name: "SAN FRANCISCO",
      provinceCode: "05",
    },
    {
      value: "05656",
      name: "SAN JERÓNIMO",
      provinceCode: "05",
    },
    {
      value: "23466",
      name: "MONTELÍBANO",
      provinceCode: "23",
    },
    {
      value: "86568",
      name: "PUERTO ASÍS",
      provinceCode: "86",
    },
    {
      value: "05660",
      name: "SAN LUIS",
      provinceCode: "05",
    },
    {
      value: "05664",
      name: "SAN PEDRO",
      provinceCode: "05",
    },
    {
      value: "70215",
      name: "COROZAL",
      provinceCode: "70",
    },
    {
      value: "05667",
      name: "SAN RAFAEL",
      provinceCode: "05",
    },
    {
      value: "05670",
      name: "SAN ROQUE",
      provinceCode: "05",
    },
    {
      value: "05674",
      name: "SAN VICENTE",
      provinceCode: "05",
    },
    {
      value: "05679",
      name: "SANTA BÁRBARA",
      provinceCode: "05",
    },
    {
      value: "52110",
      name: "BUESACO",
      provinceCode: "52",
    },
    {
      value: "05690",
      name: "SANTO DOMINGO",
      provinceCode: "05",
    },
    {
      value: "05697",
      name: "EL SANTUARIO",
      provinceCode: "05",
    },
    {
      value: "05736",
      name: "SEGOVIA",
      provinceCode: "05",
    },
    {
      value: "05761",
      name: "SOPETRÁN",
      provinceCode: "05",
    },
    {
      value: "05789",
      name: "TÁMESIS",
      provinceCode: "05",
    },
    {
      value: "05790",
      name: "TARAZÁ",
      provinceCode: "05",
    },
    {
      value: "05792",
      name: "TARSO",
      provinceCode: "05",
    },
    {
      value: "05809",
      name: "TITIRIBÍ",
      provinceCode: "05",
    },
    {
      value: "05819",
      name: "TOLEDO",
      provinceCode: "05",
    },
    {
      value: "05837",
      name: "TURBO",
      provinceCode: "05",
    },
    {
      value: "05842",
      name: "URAMITA",
      provinceCode: "05",
    },
    {
      value: "05847",
      name: "URRAO",
      provinceCode: "05",
    },
    {
      value: "05854",
      name: "VALDIVIA",
      provinceCode: "05",
    },
    {
      value: "05856",
      name: "VALPARAÍSO",
      provinceCode: "05",
    },
    {
      value: "05858",
      name: "VEGACHÍ",
      provinceCode: "05",
    },
    {
      value: "05861",
      name: "VENECIA",
      provinceCode: "05",
    },
    {
      value: "85139",
      name: "MANÍ",
      provinceCode: "85",
    },
    {
      value: "05885",
      name: "YALÍ",
      provinceCode: "05",
    },
    {
      value: "05887",
      name: "YARUMAL",
      provinceCode: "05",
    },
    {
      value: "05890",
      name: "YOLOMBÓ",
      provinceCode: "05",
    },
    {
      value: "05893",
      name: "YONDÓ",
      provinceCode: "05",
    },
    {
      value: "05895",
      name: "ZARAGOZA",
      provinceCode: "05",
    },
    {
      value: "08001",
      name: "BARRANQUILLA",
      provinceCode: "08",
    },
    {
      value: "08078",
      name: "BARANOA",
      provinceCode: "08",
    },
    {
      value: "13268",
      name: "EL PEÑÓN",
      provinceCode: "13",
    },
    {
      value: "08141",
      name: "CANDELARIA",
      provinceCode: "08",
    },
    {
      value: "08296",
      name: "GALAPA",
      provinceCode: "08",
    },
    {
      value: "76834",
      name: "TULUÁ",
      provinceCode: "76",
    },
    {
      value: "73152",
      name: "CASABIANCA",
      provinceCode: "73",
    },
    {
      value: "08421",
      name: "LURUACO",
      provinceCode: "08",
    },
    {
      value: "08433",
      name: "MALAMBO",
      provinceCode: "08",
    },
    {
      value: "08436",
      name: "MANATÍ",
      provinceCode: "08",
    },
    {
      value: "25040",
      name: "ANOLAIMA",
      provinceCode: "25",
    },
    {
      value: "08549",
      name: "PIOJÓ",
      provinceCode: "08",
    },
    {
      value: "08558",
      name: "POLONUEVO",
      provinceCode: "08",
    },
    {
      value: "25175",
      name: "CHÍA",
      provinceCode: "25",
    },
    {
      value: "52835",
      name: "SAN ANDRÉS DE TUMACO",
      provinceCode: "52",
    },
    {
      value: "08634",
      name: "SABANAGRANDE",
      provinceCode: "08",
    },
    {
      value: "08638",
      name: "SABANALARGA",
      provinceCode: "08",
    },
    {
      value: "08675",
      name: "SANTA LUCÍA",
      provinceCode: "08",
    },
    {
      value: "08685",
      name: "SANTO TOMÁS",
      provinceCode: "08",
    },
    {
      value: "08758",
      name: "SOLEDAD",
      provinceCode: "08",
    },
    {
      value: "08770",
      name: "SUAN",
      provinceCode: "08",
    },
    {
      value: "08832",
      name: "TUBARÁ",
      provinceCode: "08",
    },
    {
      value: "08849",
      name: "USIACURÍ",
      provinceCode: "08",
    },
    {
      value: "18460",
      name: "MILÁN",
      provinceCode: "18",
    },
    {
      value: "68147",
      name: "CAPITANEJO",
      provinceCode: "68",
    },
    {
      value: "13006",
      name: "ACHÍ",
      provinceCode: "13",
    },
    {
      value: "73043",
      name: "ANZOÁTEGUI",
      provinceCode: "73",
    },
    {
      value: "13042",
      name: "ARENAL",
      provinceCode: "13",
    },
    {
      value: "13052",
      name: "ARJONA",
      provinceCode: "13",
    },
    {
      value: "13062",
      name: "ARROYOHONDO",
      provinceCode: "13",
    },
    {
      value: "76275",
      name: "FLORIDA",
      provinceCode: "76",
    },
    {
      value: "13140",
      name: "CALAMAR",
      provinceCode: "13",
    },
    {
      value: "13160",
      name: "CANTAGALLO",
      provinceCode: "13",
    },
    {
      value: "13188",
      name: "CICUCO",
      provinceCode: "13",
    },
    {
      value: "13212",
      name: "Córdoba",
      provinceCode: "13",
    },
    {
      value: "13222",
      name: "CLEMENCIA",
      provinceCode: "13",
    },
    {
      value: "08606",
      name: "REPELÓN",
      provinceCode: "08",
    },
    {
      value: "13248",
      name: "EL GUAMO",
      provinceCode: "13",
    },
    {
      value: "05284",
      name: "FRONTINO",
      provinceCode: "05",
    },
    {
      value: "13430",
      name: "MAGANGUÉ",
      provinceCode: "13",
    },
    {
      value: "13433",
      name: "MAHATES",
      provinceCode: "13",
    },
    {
      value: "13440",
      name: "MARGARITA",
      provinceCode: "13",
    },
    {
      value: "13458",
      name: "MONTECRISTO",
      provinceCode: "13",
    },
    {
      value: "13468",
      name: "MOMPÓS",
      provinceCode: "13",
    },
    {
      value: "13473",
      name: "MORALES",
      provinceCode: "13",
    },
    {
      value: "13490",
      name: "NOROSÍ",
      provinceCode: "13",
    },
    {
      value: "13549",
      name: "PINILLOS",
      provinceCode: "13",
    },
    {
      value: "13580",
      name: "REGIDOR",
      provinceCode: "13",
    },
    {
      value: "13600",
      name: "RÍO VIEJO",
      provinceCode: "13",
    },
    {
      value: "13647",
      name: "SAN ESTANISLAO",
      provinceCode: "13",
    },
    {
      value: "13650",
      name: "SAN FERNANDO",
      provinceCode: "13",
    },
    {
      value: "25258",
      name: "EL PEÑÓN",
      provinceCode: "25",
    },
    {
      value: "54520",
      name: "PAMPLONITA",
      provinceCode: "54",
    },
    {
      value: "13657",
      name: "SAN JUAN NEPOMUCENO",
      provinceCode: "13",
    },
    {
      value: "91460",
      name: "MIRITI PARANÁ",
      provinceCode: "91",
    },
    {
      value: "85400",
      name: "TÁMARA",
      provinceCode: "85",
    },
    {
      value: "13673",
      name: "SANTA CATALINA",
      provinceCode: "13",
    },
    {
      value: "13683",
      name: "SANTA ROSA",
      provinceCode: "13",
    },
    {
      value: "15806",
      name: "TIBASOSA",
      provinceCode: "15",
    },
    {
      value: "13744",
      name: "SIMITÍ",
      provinceCode: "13",
    },
    {
      value: "13760",
      name: "SOPLAVIENTO",
      provinceCode: "13",
    },
    {
      value: "13780",
      name: "TALAIGUA NUEVO",
      provinceCode: "13",
    },
    {
      value: "13810",
      name: "TIQUISIO",
      provinceCode: "13",
    },
    {
      value: "13836",
      name: "TURBACO",
      provinceCode: "13",
    },
    {
      value: "13838",
      name: "TURBANÁ",
      provinceCode: "13",
    },
    {
      value: "13873",
      name: "VILLANUEVA",
      provinceCode: "13",
    },
    {
      value: "19517",
      name: "PÁEZ",
      provinceCode: "19",
    },
    {
      value: "15001",
      name: "TUNJA",
      provinceCode: "15",
    },
    {
      value: "15022",
      name: "ALMEIDA",
      provinceCode: "15",
    },
    {
      value: "15047",
      name: "AQUITANIA",
      provinceCode: "15",
    },
    {
      value: "15051",
      name: "ARCABUCO",
      provinceCode: "15",
    },
    {
      value: "73001",
      name: "IBAGUÉ",
      provinceCode: "73",
    },
    {
      value: "15090",
      name: "BERBEO",
      provinceCode: "15",
    },
    {
      value: "15092",
      name: "BETÉITIVA",
      provinceCode: "15",
    },
    {
      value: "15097",
      name: "BOAVITA",
      provinceCode: "15",
    },
    {
      value: "15104",
      name: "Boyacá",
      provinceCode: "15",
    },
    {
      value: "15106",
      name: "BRICEÑO",
      provinceCode: "15",
    },
    {
      value: "15109",
      name: "BUENA VISTA",
      provinceCode: "15",
    },
    {
      value: "15114",
      name: "BUSBANZÁ",
      provinceCode: "15",
    },
    {
      value: "15131",
      name: "CALDAS",
      provinceCode: "15",
    },
    {
      value: "15135",
      name: "CAMPOHERMOSO",
      provinceCode: "15",
    },
    {
      value: "15162",
      name: "CERINZA",
      provinceCode: "15",
    },
    {
      value: "15172",
      name: "CHINAVITA",
      provinceCode: "15",
    },
    {
      value: "15176",
      name: "CHIQUINQUIRÁ",
      provinceCode: "15",
    },
    {
      value: "15180",
      name: "CHISCAS",
      provinceCode: "15",
    },
    {
      value: "15183",
      name: "CHITA",
      provinceCode: "15",
    },
    {
      value: "15185",
      name: "CHITARAQUE",
      provinceCode: "15",
    },
    {
      value: "15187",
      name: "CHIVATÁ",
      provinceCode: "15",
    },
    {
      value: "15204",
      name: "CÓMBITA",
      provinceCode: "15",
    },
    {
      value: "15212",
      name: "COPER",
      provinceCode: "15",
    },
    {
      value: "15215",
      name: "CORRALES",
      provinceCode: "15",
    },
    {
      value: "15218",
      name: "COVARACHÍA",
      provinceCode: "15",
    },
    {
      value: "15223",
      name: "CUBARÁ",
      provinceCode: "15",
    },
    {
      value: "15224",
      name: "CUCAITA",
      provinceCode: "15",
    },
    {
      value: "15226",
      name: "CUÍTIVA",
      provinceCode: "15",
    },
    {
      value: "15232",
      name: "CHÍQUIZA",
      provinceCode: "15",
    },
    {
      value: "15236",
      name: "CHIVOR",
      provinceCode: "15",
    },
    {
      value: "15238",
      name: "DUITAMA",
      provinceCode: "15",
    },
    {
      value: "15244",
      name: "EL COCUY",
      provinceCode: "15",
    },
    {
      value: "15248",
      name: "EL ESPINO",
      provinceCode: "15",
    },
    {
      value: "15272",
      name: "FIRAVITOBA",
      provinceCode: "15",
    },
    {
      value: "15276",
      name: "FLORESTA",
      provinceCode: "15",
    },
    {
      value: "15293",
      name: "GACHANTIVÁ",
      provinceCode: "15",
    },
    {
      value: "15296",
      name: "GAMEZA",
      provinceCode: "15",
    },
    {
      value: "15299",
      name: "GARAGOA",
      provinceCode: "15",
    },
    {
      value: "15317",
      name: "GUACAMAYAS",
      provinceCode: "15",
    },
    {
      value: "15322",
      name: "GUATEQUE",
      provinceCode: "15",
    },
    {
      value: "15325",
      name: "GUAYATÁ",
      provinceCode: "15",
    },
    {
      value: "15332",
      name: "GÜICÁN",
      provinceCode: "15",
    },
    {
      value: "15362",
      name: "IZA",
      provinceCode: "15",
    },
    {
      value: "15367",
      name: "JENESANO",
      provinceCode: "15",
    },
    {
      value: "15368",
      name: "JERICÓ",
      provinceCode: "15",
    },
    {
      value: "15377",
      name: "LABRANZAGRANDE",
      provinceCode: "15",
    },
    {
      value: "15380",
      name: "LA CAPILLA",
      provinceCode: "15",
    },
    {
      value: "15401",
      name: "LA VICTORIA",
      provinceCode: "15",
    },
    {
      value: "08573",
      name: "PUERTO COLOMBIA",
      provinceCode: "08",
    },
    {
      value: "52083",
      name: "BELÉN",
      provinceCode: "52",
    },
    {
      value: "15425",
      name: "MACANAL",
      provinceCode: "15",
    },
    {
      value: "15442",
      name: "MARIPÍ",
      provinceCode: "15",
    },
    {
      value: "15455",
      name: "MIRAFLORES",
      provinceCode: "15",
    },
    {
      value: "15464",
      name: "MONGUA",
      provinceCode: "15",
    },
    {
      value: "15466",
      name: "MONGUÍ",
      provinceCode: "15",
    },
    {
      value: "15469",
      name: "MONIQUIRÁ",
      provinceCode: "15",
    },
    {
      value: "15480",
      name: "MUZO",
      provinceCode: "15",
    },
    {
      value: "15491",
      name: "NOBSA",
      provinceCode: "15",
    },
    {
      value: "15494",
      name: "NUEVO COLÓN",
      provinceCode: "15",
    },
    {
      value: "15500",
      name: "OICATÁ",
      provinceCode: "15",
    },
    {
      value: "15507",
      name: "OTANCHE",
      provinceCode: "15",
    },
    {
      value: "15511",
      name: "PACHAVITA",
      provinceCode: "15",
    },
    {
      value: "15514",
      name: "PÁEZ",
      provinceCode: "15",
    },
    {
      value: "15516",
      name: "PAIPA",
      provinceCode: "15",
    },
    {
      value: "15518",
      name: "PAJARITO",
      provinceCode: "15",
    },
    {
      value: "15522",
      name: "PANQUEBA",
      provinceCode: "15",
    },
    {
      value: "15531",
      name: "PAUNA",
      provinceCode: "15",
    },
    {
      value: "15533",
      name: "PAYA",
      provinceCode: "15",
    },
    {
      value: "25758",
      name: "SOPÓ",
      provinceCode: "25",
    },
    {
      value: "15542",
      name: "PESCA",
      provinceCode: "15",
    },
    {
      value: "15550",
      name: "PISBA",
      provinceCode: "15",
    },
    {
      value: "15572",
      name: "PUERTO BOYACÁ",
      provinceCode: "15",
    },
    {
      value: "15580",
      name: "QUÍPAMA",
      provinceCode: "15",
    },
    {
      value: "15599",
      name: "RAMIRIQUÍ",
      provinceCode: "15",
    },
    {
      value: "15600",
      name: "RÁQUIRA",
      provinceCode: "15",
    },
    {
      value: "15621",
      name: "RONDÓN",
      provinceCode: "15",
    },
    {
      value: "15632",
      name: "SABOYÁ",
      provinceCode: "15",
    },
    {
      value: "15638",
      name: "SÁCHICA",
      provinceCode: "15",
    },
    {
      value: "15646",
      name: "SAMACÁ",
      provinceCode: "15",
    },
    {
      value: "15660",
      name: "SAN EDUARDO",
      provinceCode: "15",
    },
    {
      value: "27150",
      name: "CARMEN DEL DARIEN",
      provinceCode: "27",
    },
    {
      value: "25299",
      name: "GAMA",
      provinceCode: "25",
    },
    {
      value: "15673",
      name: "SAN MATEO",
      provinceCode: "15",
    },
    {
      value: "25718",
      name: "SASAIMA",
      provinceCode: "25",
    },
    {
      value: "52240",
      name: "CHACHAGÜÍ",
      provinceCode: "52",
    },
    {
      value: "15686",
      name: "SANTANA",
      provinceCode: "15",
    },
    {
      value: "15690",
      name: "SANTA MARÍA",
      provinceCode: "15",
    },
    {
      value: "54001",
      name: "CÚCUTA",
      provinceCode: "54",
    },
    {
      value: "15696",
      name: "SANTA SOFÍA",
      provinceCode: "15",
    },
    {
      value: "15720",
      name: "SATIVANORTE",
      provinceCode: "15",
    },
    {
      value: "15723",
      name: "SATIVASUR",
      provinceCode: "15",
    },
    {
      value: "15740",
      name: "SIACHOQUE",
      provinceCode: "15",
    },
    {
      value: "15753",
      name: "SOATÁ",
      provinceCode: "15",
    },
    {
      value: "15755",
      name: "SOCOTÁ",
      provinceCode: "15",
    },
    {
      value: "15757",
      name: "SOCHA",
      provinceCode: "15",
    },
    {
      value: "15759",
      name: "SOGAMOSO",
      provinceCode: "15",
    },
    {
      value: "15761",
      name: "SOMONDOCO",
      provinceCode: "15",
    },
    {
      value: "15762",
      name: "SORA",
      provinceCode: "15",
    },
    {
      value: "15763",
      name: "SOTAQUIRÁ",
      provinceCode: "15",
    },
    {
      value: "15764",
      name: "SORACÁ",
      provinceCode: "15",
    },
    {
      value: "15774",
      name: "SUSACÓN",
      provinceCode: "15",
    },
    {
      value: "15776",
      name: "SUTAMARCHÁN",
      provinceCode: "15",
    },
    {
      value: "15778",
      name: "SUTATENZA",
      provinceCode: "15",
    },
    {
      value: "15790",
      name: "TASCO",
      provinceCode: "15",
    },
    {
      value: "15798",
      name: "TENZA",
      provinceCode: "15",
    },
    {
      value: "15804",
      name: "TIBANÁ",
      provinceCode: "15",
    },
    {
      value: "15808",
      name: "TINJACÁ",
      provinceCode: "15",
    },
    {
      value: "15810",
      name: "TIPACOQUE",
      provinceCode: "15",
    },
    {
      value: "15814",
      name: "TOCA",
      provinceCode: "15",
    },
    {
      value: "13001",
      name: "CARTAGENA",
      provinceCode: "13",
    },
    {
      value: "15820",
      name: "TÓPAGA",
      provinceCode: "15",
    },
    {
      value: "15822",
      name: "TOTA",
      provinceCode: "15",
    },
    {
      value: "15835",
      name: "TURMEQUÉ",
      provinceCode: "15",
    },
    {
      value: "05313",
      name: "GRANADA",
      provinceCode: "05",
    },
    {
      value: "15839",
      name: "TUTAZÁ",
      provinceCode: "15",
    },
    {
      value: "15842",
      name: "UMBITA",
      provinceCode: "15",
    },
    {
      value: "15861",
      name: "VENTAQUEMADA",
      provinceCode: "15",
    },
    {
      value: "15879",
      name: "VIRACACHÁ",
      provinceCode: "15",
    },
    {
      value: "15897",
      name: "ZETAQUIRA",
      provinceCode: "15",
    },
    {
      value: "17001",
      name: "MANIZALES",
      provinceCode: "17",
    },
    {
      value: "17013",
      name: "AGUADAS",
      provinceCode: "17",
    },
    {
      value: "17042",
      name: "ANSERMA",
      provinceCode: "17",
    },
    {
      value: "17050",
      name: "ARANZAZU",
      provinceCode: "17",
    },
    {
      value: "17088",
      name: "BELALCÁZAR",
      provinceCode: "17",
    },
    {
      value: "17174",
      name: "CHINCHINÁ",
      provinceCode: "17",
    },
    {
      value: "17272",
      name: "FILADELFIA",
      provinceCode: "17",
    },
    {
      value: "17380",
      name: "LA DORADA",
      provinceCode: "17",
    },
    {
      value: "17388",
      name: "LA MERCED",
      provinceCode: "17",
    },
    {
      value: "17433",
      name: "MANZANARES",
      provinceCode: "17",
    },
    {
      value: "17442",
      name: "MARMATO",
      provinceCode: "17",
    },
    {
      value: "17446",
      name: "MARULANDA",
      provinceCode: "17",
    },
    {
      value: "17486",
      name: "NEIRA",
      provinceCode: "17",
    },
    {
      value: "17495",
      name: "NORCASIA",
      provinceCode: "17",
    },
    {
      value: "17513",
      name: "PÁCORA",
      provinceCode: "17",
    },
    {
      value: "17524",
      name: "PALESTINA",
      provinceCode: "17",
    },
    {
      value: "17541",
      name: "PENSILVANIA",
      provinceCode: "17",
    },
    {
      value: "17614",
      name: "RIOSUCIO",
      provinceCode: "17",
    },
    {
      value: "17616",
      name: "RISARALDA",
      provinceCode: "17",
    },
    {
      value: "17653",
      name: "SALAMINA",
      provinceCode: "17",
    },
    {
      value: "17662",
      name: "SAMANÁ",
      provinceCode: "17",
    },
    {
      value: "17665",
      name: "SAN JOSÉ",
      provinceCode: "17",
    },
    {
      value: "17777",
      name: "SUPÍA",
      provinceCode: "17",
    },
    {
      value: "17867",
      name: "VICTORIA",
      provinceCode: "17",
    },
    {
      value: "17873",
      name: "VILLAMARÍA",
      provinceCode: "17",
    },
    {
      value: "17877",
      name: "VITERBO",
      provinceCode: "17",
    },
    {
      value: "18001",
      name: "FLORENCIA",
      provinceCode: "18",
    },
    {
      value: "18029",
      name: "ALBANIA",
      provinceCode: "18",
    },
    {
      value: "47720",
      name: "SANTA BÁRBARA DE PINTO",
      provinceCode: "47",
    },
    {
      value: "13442",
      name: "MARÍA LA BAJA",
      provinceCode: "13",
    },
    {
      value: "18205",
      name: "CURILLO",
      provinceCode: "18",
    },
    {
      value: "18247",
      name: "EL DONCELLO",
      provinceCode: "18",
    },
    {
      value: "18256",
      name: "EL PAUJIL",
      provinceCode: "18",
    },
    {
      value: "18479",
      name: "MORELIA",
      provinceCode: "18",
    },
    {
      value: "18592",
      name: "PUERTO RICO",
      provinceCode: "18",
    },
    {
      value: "18410",
      name: "LA MONTAÑITA",
      provinceCode: "18",
    },
    {
      value: "18753",
      name: "SAN VICENTE DEL CAGUÁN",
      provinceCode: "18",
    },
    {
      value: "18756",
      name: "SOLANO",
      provinceCode: "18",
    },
    {
      value: "18785",
      name: "SOLITA",
      provinceCode: "18",
    },
    {
      value: "18860",
      name: "VALPARAÍSO",
      provinceCode: "18",
    },
    {
      value: "19001",
      name: "POPAYÁN",
      provinceCode: "19",
    },
    {
      value: "19022",
      name: "ALMAGUER",
      provinceCode: "19",
    },
    {
      value: "19050",
      name: "ARGELIA",
      provinceCode: "19",
    },
    {
      value: "19075",
      name: "BALBOA",
      provinceCode: "19",
    },
    {
      value: "19100",
      name: "Bolívar",
      provinceCode: "19",
    },
    {
      value: "19110",
      name: "BUENOS AIRES",
      provinceCode: "19",
    },
    {
      value: "19130",
      name: "CAJIBÍO",
      provinceCode: "19",
    },
    {
      value: "19137",
      name: "CALDONO",
      provinceCode: "19",
    },
    {
      value: "19142",
      name: "CALOTO",
      provinceCode: "19",
    },
    {
      value: "19212",
      name: "CORINTO",
      provinceCode: "19",
    },
    {
      value: "19256",
      name: "EL TAMBO",
      provinceCode: "19",
    },
    {
      value: "19290",
      name: "FLORENCIA",
      provinceCode: "19",
    },
    {
      value: "19300",
      name: "GUACHENÉ",
      provinceCode: "19",
    },
    {
      value: "19318",
      name: "GUAPI",
      provinceCode: "19",
    },
    {
      value: "19355",
      name: "INZÁ",
      provinceCode: "19",
    },
    {
      value: "19364",
      name: "JAMBALÓ",
      provinceCode: "19",
    },
    {
      value: "19392",
      name: "LA SIERRA",
      provinceCode: "19",
    },
    {
      value: "19397",
      name: "LA VEGA",
      provinceCode: "19",
    },
    {
      value: "19418",
      name: "LÓPEZ",
      provinceCode: "19",
    },
    {
      value: "19450",
      name: "MERCADERES",
      provinceCode: "19",
    },
    {
      value: "19455",
      name: "MIRANDA",
      provinceCode: "19",
    },
    {
      value: "19473",
      name: "MORALES",
      provinceCode: "19",
    },
    {
      value: "19513",
      name: "PADILLA",
      provinceCode: "19",
    },
    {
      value: "19532",
      name: "PATÍA",
      provinceCode: "19",
    },
    {
      value: "19533",
      name: "PIAMONTE",
      provinceCode: "19",
    },
    {
      value: "19548",
      name: "PIENDAMÓ",
      provinceCode: "19",
    },
    {
      value: "19573",
      name: "PUERTO TEJADA",
      provinceCode: "19",
    },
    {
      value: "19585",
      name: "PURACÉ",
      provinceCode: "19",
    },
    {
      value: "19622",
      name: "ROSAS",
      provinceCode: "19",
    },
    {
      value: "68250",
      name: "EL PEÑÓN",
      provinceCode: "68",
    },
    {
      value: "05364",
      name: "JARDÍN",
      provinceCode: "05",
    },
    {
      value: "19701",
      name: "SANTA ROSA",
      provinceCode: "19",
    },
    {
      value: "19743",
      name: "SILVIA",
      provinceCode: "19",
    },
    {
      value: "19760",
      name: "SOTARA",
      provinceCode: "19",
    },
    {
      value: "19780",
      name: "SUÁREZ",
      provinceCode: "19",
    },
    {
      value: "19785",
      name: "Sucre",
      provinceCode: "19",
    },
    {
      value: "19807",
      name: "TIMBÍO",
      provinceCode: "19",
    },
    {
      value: "19809",
      name: "TIMBIQUÍ",
      provinceCode: "19",
    },
    {
      value: "19821",
      name: "TORIBIO",
      provinceCode: "19",
    },
    {
      value: "19824",
      name: "TOTORÓ",
      provinceCode: "19",
    },
    {
      value: "19845",
      name: "VILLA RICA",
      provinceCode: "19",
    },
    {
      value: "20001",
      name: "VALLEDUPAR",
      provinceCode: "20",
    },
    {
      value: "20011",
      name: "AGUACHICA",
      provinceCode: "20",
    },
    {
      value: "20013",
      name: "AGUSTÍN CODAZZI",
      provinceCode: "20",
    },
    {
      value: "20032",
      name: "ASTREA",
      provinceCode: "20",
    },
    {
      value: "20045",
      name: "BECERRIL",
      provinceCode: "20",
    },
    {
      value: "20060",
      name: "BOSCONIA",
      provinceCode: "20",
    },
    {
      value: "20175",
      name: "CHIMICHAGUA",
      provinceCode: "20",
    },
    {
      value: "20178",
      name: "CHIRIGUANÁ",
      provinceCode: "20",
    },
    {
      value: "20228",
      name: "CURUMANÍ",
      provinceCode: "20",
    },
    {
      value: "20238",
      name: "EL COPEY",
      provinceCode: "20",
    },
    {
      value: "20250",
      name: "EL PASO",
      provinceCode: "20",
    },
    {
      value: "20295",
      name: "GAMARRA",
      provinceCode: "20",
    },
    {
      value: "20310",
      name: "GONZÁLEZ",
      provinceCode: "20",
    },
    {
      value: "20383",
      name: "LA GLORIA",
      provinceCode: "20",
    },
    {
      value: "76364",
      name: "JAMUNDÍ",
      provinceCode: "76",
    },
    {
      value: "20443",
      name: "MANAURE",
      provinceCode: "20",
    },
    {
      value: "20517",
      name: "PAILITAS",
      provinceCode: "20",
    },
    {
      value: "20550",
      name: "PELAYA",
      provinceCode: "20",
    },
    {
      value: "20570",
      name: "PUEBLO BELLO",
      provinceCode: "20",
    },
    {
      value: "27787",
      name: "TADÓ",
      provinceCode: "27",
    },
    {
      value: "20621",
      name: "LA PAZ",
      provinceCode: "20",
    },
    {
      value: "20710",
      name: "SAN ALBERTO",
      provinceCode: "20",
    },
    {
      value: "20750",
      name: "SAN DIEGO",
      provinceCode: "20",
    },
    {
      value: "20770",
      name: "SAN MARTÍN",
      provinceCode: "20",
    },
    {
      value: "20787",
      name: "TAMALAMEQUE",
      provinceCode: "20",
    },
    {
      value: "23001",
      name: "MONTERÍA",
      provinceCode: "23",
    },
    {
      value: "23068",
      name: "AYAPEL",
      provinceCode: "23",
    },
    {
      value: "23079",
      name: "BUENAVISTA",
      provinceCode: "23",
    },
    {
      value: "23090",
      name: "CANALETE",
      provinceCode: "23",
    },
    {
      value: "23162",
      name: "CERETÉ",
      provinceCode: "23",
    },
    {
      value: "23168",
      name: "CHIMÁ",
      provinceCode: "23",
    },
    {
      value: "23182",
      name: "CHINÚ",
      provinceCode: "23",
    },
    {
      value: "85230",
      name: "OROCUÉ",
      provinceCode: "85",
    },
    {
      value: "23300",
      name: "COTORRA",
      provinceCode: "23",
    },
    {
      value: "73411",
      name: "LÍBANO",
      provinceCode: "73",
    },
    {
      value: "23417",
      name: "LORICA",
      provinceCode: "23",
    },
    {
      value: "23419",
      name: "LOS CÓRDOBAS",
      provinceCode: "23",
    },
    {
      value: "23464",
      name: "MOMIL",
      provinceCode: "23",
    },
    {
      value: "23500",
      name: "MOÑITOS",
      provinceCode: "23",
    },
    {
      value: "23555",
      name: "PLANETA RICA",
      provinceCode: "23",
    },
    {
      value: "23570",
      name: "PUEBLO NUEVO",
      provinceCode: "23",
    },
    {
      value: "23574",
      name: "PUERTO ESCONDIDO",
      provinceCode: "23",
    },
    {
      value: "25885",
      name: "YACOPÍ",
      provinceCode: "25",
    },
    {
      value: "23586",
      name: "PURÍSIMA",
      provinceCode: "23",
    },
    {
      value: "23660",
      name: "SAHAGÚN",
      provinceCode: "23",
    },
    {
      value: "23670",
      name: "SAN ANDRÉS SOTAVENTO",
      provinceCode: "23",
    },
    {
      value: "23672",
      name: "SAN ANTERO",
      provinceCode: "23",
    },
    {
      value: "63130",
      name: "CALARCÁ",
      provinceCode: "63",
    },
    {
      value: "05756",
      name: "SONSÓN",
      provinceCode: "05",
    },
    {
      value: "54245",
      name: "EL CARMEN",
      provinceCode: "54",
    },
    {
      value: "23686",
      name: "SAN PELAYO",
      provinceCode: "23",
    },
    {
      value: "23807",
      name: "TIERRALTA",
      provinceCode: "23",
    },
    {
      value: "23815",
      name: "TUCHÍN",
      provinceCode: "23",
    },
    {
      value: "23855",
      name: "VALENCIA",
      provinceCode: "23",
    },
    {
      value: "73408",
      name: "LÉRIDA",
      provinceCode: "73",
    },
    {
      value: "25035",
      name: "ANAPOIMA",
      provinceCode: "25",
    },
    {
      value: "25053",
      name: "ARBELÁEZ",
      provinceCode: "25",
    },
    {
      value: "25086",
      name: "BELTRÁN",
      provinceCode: "25",
    },
    {
      value: "25095",
      name: "BITUIMA",
      provinceCode: "25",
    },
    {
      value: "25099",
      name: "BOJACÁ",
      provinceCode: "25",
    },
    {
      value: "25120",
      name: "CABRERA",
      provinceCode: "25",
    },
    {
      value: "25123",
      name: "CACHIPAY",
      provinceCode: "25",
    },
    {
      value: "25126",
      name: "CAJICÁ",
      provinceCode: "25",
    },
    {
      value: "25148",
      name: "CAPARRAPÍ",
      provinceCode: "25",
    },
    {
      value: "25151",
      name: "CAQUEZA",
      provinceCode: "25",
    },
    {
      value: "23350",
      name: "LA APARTADA",
      provinceCode: "23",
    },
    {
      value: "25168",
      name: "CHAGUANÍ",
      provinceCode: "25",
    },
    {
      value: "25178",
      name: "CHIPAQUE",
      provinceCode: "25",
    },
    {
      value: "25181",
      name: "CHOACHÍ",
      provinceCode: "25",
    },
    {
      value: "25183",
      name: "CHOCONTÁ",
      provinceCode: "25",
    },
    {
      value: "25200",
      name: "COGUA",
      provinceCode: "25",
    },
    {
      value: "25214",
      name: "COTA",
      provinceCode: "25",
    },
    {
      value: "25224",
      name: "CUCUNUBÁ",
      provinceCode: "25",
    },
    {
      value: "25245",
      name: "EL COLEGIO",
      provinceCode: "25",
    },
    {
      value: "25260",
      name: "EL ROSAL",
      provinceCode: "25",
    },
    {
      value: "25279",
      name: "FOMEQUE",
      provinceCode: "25",
    },
    {
      value: "25281",
      name: "FOSCA",
      provinceCode: "25",
    },
    {
      value: "25286",
      name: "FUNZA",
      provinceCode: "25",
    },
    {
      value: "25288",
      name: "FÚQUENE",
      provinceCode: "25",
    },
    {
      value: "25293",
      name: "GACHALA",
      provinceCode: "25",
    },
    {
      value: "25295",
      name: "GACHANCIPÁ",
      provinceCode: "25",
    },
    {
      value: "25297",
      name: "GACHETÁ",
      provinceCode: "25",
    },
    {
      value: "13620",
      name: "SAN CRISTÓBAL",
      provinceCode: "13",
    },
    {
      value: "25307",
      name: "GIRARDOT",
      provinceCode: "25",
    },
    {
      value: "25312",
      name: "GRANADA",
      provinceCode: "25",
    },
    {
      value: "25317",
      name: "GUACHETÁ",
      provinceCode: "25",
    },
    {
      value: "25320",
      name: "GUADUAS",
      provinceCode: "25",
    },
    {
      value: "25322",
      name: "GUASCA",
      provinceCode: "25",
    },
    {
      value: "25324",
      name: "GUATAQUÍ",
      provinceCode: "25",
    },
    {
      value: "25326",
      name: "GUATAVITA",
      provinceCode: "25",
    },
    {
      value: "25290",
      name: "FUSAGASUGÁ",
      provinceCode: "25",
    },
    {
      value: "25335",
      name: "GUAYABETAL",
      provinceCode: "25",
    },
    {
      value: "25339",
      name: "GUTIÉRREZ",
      provinceCode: "25",
    },
    {
      value: "25368",
      name: "JERUSALÉN",
      provinceCode: "25",
    },
    {
      value: "25372",
      name: "JUNÍN",
      provinceCode: "25",
    },
    {
      value: "25377",
      name: "LA CALERA",
      provinceCode: "25",
    },
    {
      value: "25386",
      name: "LA MESA",
      provinceCode: "25",
    },
    {
      value: "25394",
      name: "LA PALMA",
      provinceCode: "25",
    },
    {
      value: "25398",
      name: "LA PEÑA",
      provinceCode: "25",
    },
    {
      value: "25402",
      name: "LA VEGA",
      provinceCode: "25",
    },
    {
      value: "25407",
      name: "LENGUAZAQUE",
      provinceCode: "25",
    },
    {
      value: "25426",
      name: "MACHETA",
      provinceCode: "25",
    },
    {
      value: "25430",
      name: "MADRID",
      provinceCode: "25",
    },
    {
      value: "25436",
      name: "MANTA",
      provinceCode: "25",
    },
    {
      value: "25438",
      name: "MEDINA",
      provinceCode: "25",
    },
    {
      value: "25473",
      name: "MOSQUERA",
      provinceCode: "25",
    },
    {
      value: "25483",
      name: "Nariño",
      provinceCode: "25",
    },
    {
      value: "25486",
      name: "NEMOCÓN",
      provinceCode: "25",
    },
    {
      value: "25488",
      name: "NILO",
      provinceCode: "25",
    },
    {
      value: "25489",
      name: "NIMAIMA",
      provinceCode: "25",
    },
    {
      value: "25491",
      name: "NOCAIMA",
      provinceCode: "25",
    },
    {
      value: "25506",
      name: "VENECIA",
      provinceCode: "25",
    },
    {
      value: "25513",
      name: "PACHO",
      provinceCode: "25",
    },
    {
      value: "25518",
      name: "PAIME",
      provinceCode: "25",
    },
    {
      value: "25524",
      name: "PANDI",
      provinceCode: "25",
    },
    {
      value: "25530",
      name: "PARATEBUENO",
      provinceCode: "25",
    },
    {
      value: "25535",
      name: "PASCA",
      provinceCode: "25",
    },
    {
      value: "25572",
      name: "PUERTO SALGAR",
      provinceCode: "25",
    },
    {
      value: "25580",
      name: "PULÍ",
      provinceCode: "25",
    },
    {
      value: "25592",
      name: "QUEBRADANEGRA",
      provinceCode: "25",
    },
    {
      value: "25594",
      name: "QUETAME",
      provinceCode: "25",
    },
    {
      value: "25596",
      name: "QUIPILE",
      provinceCode: "25",
    },
    {
      value: "25599",
      name: "APULO",
      provinceCode: "25",
    },
    {
      value: "25612",
      name: "RICAURTE",
      provinceCode: "25",
    },
    {
      value: "13894",
      name: "ZAMBRANO",
      provinceCode: "13",
    },
    {
      value: "25649",
      name: "SAN BERNARDO",
      provinceCode: "25",
    },
    {
      value: "25653",
      name: "SAN CAYETANO",
      provinceCode: "25",
    },
    {
      value: "25658",
      name: "SAN FRANCISCO",
      provinceCode: "25",
    },
    {
      value: "15403",
      name: "LA UVITA",
      provinceCode: "15",
    },
    {
      value: "25899",
      name: "ZIPAQUIRÁ",
      provinceCode: "25",
    },
    {
      value: "25736",
      name: "SESQUILÉ",
      provinceCode: "25",
    },
    {
      value: "25740",
      name: "SIBATÉ",
      provinceCode: "25",
    },
    {
      value: "25743",
      name: "SILVANIA",
      provinceCode: "25",
    },
    {
      value: "25745",
      name: "SIMIJACA",
      provinceCode: "25",
    },
    {
      value: "25754",
      name: "SOACHA",
      provinceCode: "25",
    },
    {
      value: "25769",
      name: "SUBACHOQUE",
      provinceCode: "25",
    },
    {
      value: "25772",
      name: "SUESCA",
      provinceCode: "25",
    },
    {
      value: "25777",
      name: "SUPATÁ",
      provinceCode: "25",
    },
    {
      value: "25779",
      name: "SUSA",
      provinceCode: "25",
    },
    {
      value: "25781",
      name: "SUTATAUSA",
      provinceCode: "25",
    },
    {
      value: "25785",
      name: "TABIO",
      provinceCode: "25",
    },
    {
      value: "63302",
      name: "GÉNOVA",
      provinceCode: "63",
    },
    {
      value: "25793",
      name: "TAUSA",
      provinceCode: "25",
    },
    {
      value: "25797",
      name: "TENA",
      provinceCode: "25",
    },
    {
      value: "25799",
      name: "TENJO",
      provinceCode: "25",
    },
    {
      value: "25805",
      name: "TIBACUY",
      provinceCode: "25",
    },
    {
      value: "25807",
      name: "TIBIRITA",
      provinceCode: "25",
    },
    {
      value: "25815",
      name: "TOCAIMA",
      provinceCode: "25",
    },
    {
      value: "25817",
      name: "TOCANCIPÁ",
      provinceCode: "25",
    },
    {
      value: "25823",
      name: "TOPAIPÍ",
      provinceCode: "25",
    },
    {
      value: "25839",
      name: "UBALÁ",
      provinceCode: "25",
    },
    {
      value: "25841",
      name: "UBAQUE",
      provinceCode: "25",
    },
    {
      value: "73770",
      name: "SUÁREZ",
      provinceCode: "73",
    },
    {
      value: "25845",
      name: "UNE",
      provinceCode: "25",
    },
    {
      value: "25851",
      name: "ÚTICA",
      provinceCode: "25",
    },
    {
      value: "50150",
      name: "CASTILLA LA NUEVA",
      provinceCode: "50",
    },
    {
      value: "25867",
      name: "VIANÍ",
      provinceCode: "25",
    },
    {
      value: "25871",
      name: "VILLAGÓMEZ",
      provinceCode: "25",
    },
    {
      value: "25873",
      name: "VILLAPINZÓN",
      provinceCode: "25",
    },
    {
      value: "25875",
      name: "VILLETA",
      provinceCode: "25",
    },
    {
      value: "25878",
      name: "VIOTÁ",
      provinceCode: "25",
    },
    {
      value: "25898",
      name: "ZIPACÓN",
      provinceCode: "25",
    },
    {
      value: "27001",
      name: "QUIBDÓ",
      provinceCode: "27",
    },
    {
      value: "27006",
      name: "ACANDÍ",
      provinceCode: "27",
    },
    {
      value: "27025",
      name: "ALTO BAUDO",
      provinceCode: "27",
    },
    {
      value: "27050",
      name: "ATRATO",
      provinceCode: "27",
    },
    {
      value: "27073",
      name: "BAGADÓ",
      provinceCode: "27",
    },
    {
      value: "27075",
      name: "BAHÍA SOLANO",
      provinceCode: "27",
    },
    {
      value: "27077",
      name: "BAJO BAUDÓ",
      provinceCode: "27",
    },
    {
      value: "15087",
      name: "BELÉN",
      provinceCode: "15",
    },
    {
      value: "27099",
      name: "BOJAYA",
      provinceCode: "27",
    },
    {
      value: "27810",
      name: "UNIÓN PANAMERICANA",
      provinceCode: "27",
    },
    {
      value: "47570",
      name: "PUEBLO VIEJO",
      provinceCode: "47",
    },
    {
      value: "27160",
      name: "CÉRTEGUI",
      provinceCode: "27",
    },
    {
      value: "27205",
      name: "CONDOTO",
      provinceCode: "27",
    },
    {
      value: "86885",
      name: "VILLAGARZÓN",
      provinceCode: "86",
    },
    {
      value: "25269",
      name: "FACATATIVÁ",
      provinceCode: "25",
    },
    {
      value: "27372",
      name: "JURADÓ",
      provinceCode: "27",
    },
    {
      value: "27413",
      name: "LLORÓ",
      provinceCode: "27",
    },
    {
      value: "27425",
      name: "MEDIO ATRATO",
      provinceCode: "27",
    },
    {
      value: "27430",
      name: "MEDIO BAUDÓ",
      provinceCode: "27",
    },
    {
      value: "27450",
      name: "MEDIO SAN JUAN",
      provinceCode: "27",
    },
    {
      value: "27491",
      name: "NÓVITA",
      provinceCode: "27",
    },
    {
      value: "27495",
      name: "NUQUÍ",
      provinceCode: "27",
    },
    {
      value: "27580",
      name: "RÍO IRO",
      provinceCode: "27",
    },
    {
      value: "27600",
      name: "RÍO QUITO",
      provinceCode: "27",
    },
    {
      value: "27615",
      name: "RIOSUCIO",
      provinceCode: "27",
    },
    {
      value: "23580",
      name: "PUERTO LIBERTADOR",
      provinceCode: "23",
    },
    {
      value: "27745",
      name: "SIPÍ",
      provinceCode: "27",
    },
    {
      value: "27800",
      name: "UNGUÍA",
      provinceCode: "27",
    },
    {
      value: "41001",
      name: "NEIVA",
      provinceCode: "41",
    },
    {
      value: "41006",
      name: "ACEVEDO",
      provinceCode: "41",
    },
    {
      value: "41013",
      name: "AGRADO",
      provinceCode: "41",
    },
    {
      value: "41016",
      name: "AIPE",
      provinceCode: "41",
    },
    {
      value: "41020",
      name: "ALGECIRAS",
      provinceCode: "41",
    },
    {
      value: "41026",
      name: "ALTAMIRA",
      provinceCode: "41",
    },
    {
      value: "41078",
      name: "BARAYA",
      provinceCode: "41",
    },
    {
      value: "41132",
      name: "CAMPOALEGRE",
      provinceCode: "41",
    },
    {
      value: "41206",
      name: "COLOMBIA",
      provinceCode: "41",
    },
    {
      value: "41244",
      name: "ELÍAS",
      provinceCode: "41",
    },
    {
      value: "41298",
      name: "GARZÓN",
      provinceCode: "41",
    },
    {
      value: "41306",
      name: "GIGANTE",
      provinceCode: "41",
    },
    {
      value: "41319",
      name: "GUADALUPE",
      provinceCode: "41",
    },
    {
      value: "41349",
      name: "HOBO",
      provinceCode: "41",
    },
    {
      value: "41357",
      name: "IQUIRA",
      provinceCode: "41",
    },
    {
      value: "41359",
      name: "ISNOS",
      provinceCode: "41",
    },
    {
      value: "41378",
      name: "LA ARGENTINA",
      provinceCode: "41",
    },
    {
      value: "41396",
      name: "LA PLATA",
      provinceCode: "41",
    },
    {
      value: "17444",
      name: "MARQUETALIA",
      provinceCode: "17",
    },
    {
      value: "41483",
      name: "NÁTAGA",
      provinceCode: "41",
    },
    {
      value: "41503",
      name: "OPORAPA",
      provinceCode: "41",
    },
    {
      value: "41518",
      name: "PAICOL",
      provinceCode: "41",
    },
    {
      value: "41524",
      name: "PALERMO",
      provinceCode: "41",
    },
    {
      value: "41530",
      name: "PALESTINA",
      provinceCode: "41",
    },
    {
      value: "41548",
      name: "PITAL",
      provinceCode: "41",
    },
    {
      value: "41551",
      name: "PITALITO",
      provinceCode: "41",
    },
    {
      value: "41615",
      name: "RIVERA",
      provinceCode: "41",
    },
    {
      value: "41660",
      name: "SALADOBLANCO",
      provinceCode: "41",
    },
    {
      value: "52051",
      name: "ARBOLEDA",
      provinceCode: "52",
    },
    {
      value: "41676",
      name: "SANTA MARÍA",
      provinceCode: "41",
    },
    {
      value: "41770",
      name: "SUAZA",
      provinceCode: "41",
    },
    {
      value: "41791",
      name: "TARQUI",
      provinceCode: "41",
    },
    {
      value: "41797",
      name: "TESALIA",
      provinceCode: "41",
    },
    {
      value: "41799",
      name: "TELLO",
      provinceCode: "41",
    },
    {
      value: "41801",
      name: "TERUEL",
      provinceCode: "41",
    },
    {
      value: "41807",
      name: "TIMANÁ",
      provinceCode: "41",
    },
    {
      value: "41872",
      name: "VILLAVIEJA",
      provinceCode: "41",
    },
    {
      value: "41885",
      name: "YAGUARÁ",
      provinceCode: "41",
    },
    {
      value: "44001",
      name: "RIOHACHA",
      provinceCode: "44",
    },
    {
      value: "44035",
      name: "ALBANIA",
      provinceCode: "44",
    },
    {
      value: "44078",
      name: "BARRANCAS",
      provinceCode: "44",
    },
    {
      value: "44090",
      name: "DIBULA",
      provinceCode: "44",
    },
    {
      value: "44098",
      name: "DISTRACCIÓN",
      provinceCode: "44",
    },
    {
      value: "44110",
      name: "EL MOLINO",
      provinceCode: "44",
    },
    {
      value: "44279",
      name: "FONSECA",
      provinceCode: "44",
    },
    {
      value: "44378",
      name: "HATONUEVO",
      provinceCode: "44",
    },
    {
      value: "44430",
      name: "MAICAO",
      provinceCode: "44",
    },
    {
      value: "44560",
      name: "MANAURE",
      provinceCode: "44",
    },
    {
      value: "44847",
      name: "URIBIA",
      provinceCode: "44",
    },
    {
      value: "44855",
      name: "URUMITA",
      provinceCode: "44",
    },
    {
      value: "44874",
      name: "VILLANUEVA",
      provinceCode: "44",
    },
    {
      value: "47001",
      name: "SANTA MARTA",
      provinceCode: "47",
    },
    {
      value: "47030",
      name: "ALGARROBO",
      provinceCode: "47",
    },
    {
      value: "47053",
      name: "ARACATACA",
      provinceCode: "47",
    },
    {
      value: "47058",
      name: "ARIGUANÍ",
      provinceCode: "47",
    },
    {
      value: "47161",
      name: "CERRO SAN ANTONIO",
      provinceCode: "47",
    },
    {
      value: "47170",
      name: "CHIVOLO",
      provinceCode: "47",
    },
    {
      value: "47205",
      name: "CONCORDIA",
      provinceCode: "47",
    },
    {
      value: "47245",
      name: "EL BANCO",
      provinceCode: "47",
    },
    {
      value: "47258",
      name: "EL PIÑON",
      provinceCode: "47",
    },
    {
      value: "47268",
      name: "EL RETÉN",
      provinceCode: "47",
    },
    {
      value: "47288",
      name: "FUNDACIÓN",
      provinceCode: "47",
    },
    {
      value: "47318",
      name: "GUAMAL",
      provinceCode: "47",
    },
    {
      value: "47460",
      name: "NUEVA GRANADA",
      provinceCode: "47",
    },
    {
      value: "47541",
      name: "PEDRAZA",
      provinceCode: "47",
    },
    {
      value: "47551",
      name: "PIVIJAY",
      provinceCode: "47",
    },
    {
      value: "47555",
      name: "PLATO",
      provinceCode: "47",
    },
    {
      value: "47605",
      name: "REMOLINO",
      provinceCode: "47",
    },
    {
      value: "47675",
      name: "SALAMINA",
      provinceCode: "47",
    },
    {
      value: "47703",
      name: "SAN ZENÓN",
      provinceCode: "47",
    },
    {
      value: "47707",
      name: "SANTA ANA",
      provinceCode: "47",
    },
    {
      value: "47745",
      name: "SITIONUEVO",
      provinceCode: "47",
    },
    {
      value: "47798",
      name: "TENERIFE",
      provinceCode: "47",
    },
    {
      value: "47960",
      name: "ZAPAYÁN",
      provinceCode: "47",
    },
    {
      value: "47980",
      name: "ZONA BANANERA",
      provinceCode: "47",
    },
    {
      value: "50001",
      name: "VILLAVICENCIO",
      provinceCode: "50",
    },
    {
      value: "50006",
      name: "ACACIAS",
      provinceCode: "50",
    },
    {
      value: "50124",
      name: "CABUYARO",
      provinceCode: "50",
    },
    {
      value: "50223",
      name: "CUBARRAL",
      provinceCode: "50",
    },
    {
      value: "50226",
      name: "CUMARAL",
      provinceCode: "50",
    },
    {
      value: "50245",
      name: "EL CALVARIO",
      provinceCode: "50",
    },
    {
      value: "50251",
      name: "EL CASTILLO",
      provinceCode: "50",
    },
    {
      value: "50270",
      name: "EL DORADO",
      provinceCode: "50",
    },
    {
      value: "76109",
      name: "BUENAVENTURA",
      provinceCode: "76",
    },
    {
      value: "50313",
      name: "GRANADA",
      provinceCode: "50",
    },
    {
      value: "50318",
      name: "GUAMAL",
      provinceCode: "50",
    },
    {
      value: "50325",
      name: "MAPIRIPÁN",
      provinceCode: "50",
    },
    {
      value: "50330",
      name: "MESETAS",
      provinceCode: "50",
    },
    {
      value: "50350",
      name: "LA MACARENA",
      provinceCode: "50",
    },
    {
      value: "50370",
      name: "URIBE",
      provinceCode: "50",
    },
    {
      value: "50400",
      name: "LEJANÍAS",
      provinceCode: "50",
    },
    {
      value: "50450",
      name: "PUERTO CONCORDIA",
      provinceCode: "50",
    },
    {
      value: "50568",
      name: "PUERTO GAITÁN",
      provinceCode: "50",
    },
    {
      value: "50573",
      name: "PUERTO LÓPEZ",
      provinceCode: "50",
    },
    {
      value: "50577",
      name: "PUERTO LLERAS",
      provinceCode: "50",
    },
    {
      value: "50590",
      name: "PUERTO RICO",
      provinceCode: "50",
    },
    {
      value: "50606",
      name: "RESTREPO",
      provinceCode: "50",
    },
    {
      value: "47189",
      name: "CIÉNAGA",
      provinceCode: "47",
    },
    {
      value: "08560",
      name: "PONEDERA",
      provinceCode: "08",
    },
    {
      value: "50686",
      name: "SAN JUANITO",
      provinceCode: "50",
    },
    {
      value: "50689",
      name: "SAN MARTÍN",
      provinceCode: "50",
    },
    {
      value: "50711",
      name: "VISTA HERMOSA",
      provinceCode: "50",
    },
    {
      value: "52001",
      name: "PASTO",
      provinceCode: "52",
    },
    {
      value: "52019",
      name: "ALBÁN",
      provinceCode: "52",
    },
    {
      value: "52022",
      name: "ALDANA",
      provinceCode: "52",
    },
    {
      value: "52036",
      name: "ANCUYÁ",
      provinceCode: "52",
    },
    {
      value: "15832",
      name: "TUNUNGUÁ",
      provinceCode: "15",
    },
    {
      value: "52079",
      name: "BARBACOAS",
      provinceCode: "52",
    },
    {
      value: "15476",
      name: "MOTAVITA",
      provinceCode: "15",
    },
    {
      value: "23675",
      name: "SAN BERNARDO DEL VIENTO",
      provinceCode: "23",
    },
    {
      value: "52203",
      name: "COLÓN",
      provinceCode: "52",
    },
    {
      value: "52207",
      name: "CONSACA",
      provinceCode: "52",
    },
    {
      value: "52210",
      name: "CONTADERO",
      provinceCode: "52",
    },
    {
      value: "52215",
      name: "Córdoba",
      provinceCode: "52",
    },
    {
      value: "52224",
      name: "CUASPUD",
      provinceCode: "52",
    },
    {
      value: "52227",
      name: "CUMBAL",
      provinceCode: "52",
    },
    {
      value: "52233",
      name: "CUMBITARA",
      provinceCode: "52",
    },
    {
      value: "52250",
      name: "EL CHARCO",
      provinceCode: "52",
    },
    {
      value: "52254",
      name: "EL PEÑOL",
      provinceCode: "52",
    },
    {
      value: "52256",
      name: "EL ROSARIO",
      provinceCode: "52",
    },
    {
      value: "27361",
      name: "ISTMINA",
      provinceCode: "27",
    },
    {
      value: "52260",
      name: "EL TAMBO",
      provinceCode: "52",
    },
    {
      value: "52287",
      name: "FUNES",
      provinceCode: "52",
    },
    {
      value: "52317",
      name: "GUACHUCAL",
      provinceCode: "52",
    },
    {
      value: "52320",
      name: "GUAITARILLA",
      provinceCode: "52",
    },
    {
      value: "52323",
      name: "GUALMATÁN",
      provinceCode: "52",
    },
    {
      value: "52352",
      name: "ILES",
      provinceCode: "52",
    },
    {
      value: "52354",
      name: "IMUÉS",
      provinceCode: "52",
    },
    {
      value: "52356",
      name: "IPIALES",
      provinceCode: "52",
    },
    {
      value: "52378",
      name: "LA CRUZ",
      provinceCode: "52",
    },
    {
      value: "52381",
      name: "LA FLORIDA",
      provinceCode: "52",
    },
    {
      value: "52385",
      name: "LA LLANADA",
      provinceCode: "52",
    },
    {
      value: "52390",
      name: "LA TOLA",
      provinceCode: "52",
    },
    {
      value: "52399",
      name: "LA UNIÓN",
      provinceCode: "52",
    },
    {
      value: "52405",
      name: "LEIVA",
      provinceCode: "52",
    },
    {
      value: "52411",
      name: "LINARES",
      provinceCode: "52",
    },
    {
      value: "52418",
      name: "LOS ANDES",
      provinceCode: "52",
    },
    {
      value: "52427",
      name: "MAGÜÍ",
      provinceCode: "52",
    },
    {
      value: "52435",
      name: "MALLAMA",
      provinceCode: "52",
    },
    {
      value: "52473",
      name: "MOSQUERA",
      provinceCode: "52",
    },
    {
      value: "52480",
      name: "Nariño",
      provinceCode: "52",
    },
    {
      value: "52490",
      name: "OLAYA HERRERA",
      provinceCode: "52",
    },
    {
      value: "52506",
      name: "OSPINA",
      provinceCode: "52",
    },
    {
      value: "52520",
      name: "FRANCISCO PIZARRO",
      provinceCode: "52",
    },
    {
      value: "52540",
      name: "POLICARPA",
      provinceCode: "52",
    },
    {
      value: "52560",
      name: "POTOSÍ",
      provinceCode: "52",
    },
    {
      value: "52565",
      name: "PROVIDENCIA",
      provinceCode: "52",
    },
    {
      value: "52573",
      name: "PUERRES",
      provinceCode: "52",
    },
    {
      value: "52585",
      name: "PUPIALES",
      provinceCode: "52",
    },
    {
      value: "52612",
      name: "RICAURTE",
      provinceCode: "52",
    },
    {
      value: "52621",
      name: "ROBERTO PAYÁN",
      provinceCode: "52",
    },
    {
      value: "52678",
      name: "SAMANIEGO",
      provinceCode: "52",
    },
    {
      value: "52683",
      name: "SANDONÁ",
      provinceCode: "52",
    },
    {
      value: "52685",
      name: "SAN BERNARDO",
      provinceCode: "52",
    },
    {
      value: "52687",
      name: "SAN LORENZO",
      provinceCode: "52",
    },
    {
      value: "52693",
      name: "SAN PABLO",
      provinceCode: "52",
    },
    {
      value: "05086",
      name: "BELMIRA",
      provinceCode: "05",
    },
    {
      value: "15189",
      name: "CIÉNEGA",
      provinceCode: "15",
    },
    {
      value: "52696",
      name: "SANTA BÁRBARA",
      provinceCode: "52",
    },
    {
      value: "52720",
      name: "SAPUYES",
      provinceCode: "52",
    },
    {
      value: "52786",
      name: "TAMINANGO",
      provinceCode: "52",
    },
    {
      value: "52788",
      name: "TANGUA",
      provinceCode: "52",
    },
    {
      value: "52699",
      name: "SANTACRUZ",
      provinceCode: "52",
    },
    {
      value: "52838",
      name: "TÚQUERRES",
      provinceCode: "52",
    },
    {
      value: "52885",
      name: "YACUANQUER",
      provinceCode: "52",
    },
    {
      value: "68575",
      name: "PUERTO WILCHES",
      provinceCode: "68",
    },
    {
      value: "68573",
      name: "PUERTO PARRA",
      provinceCode: "68",
    },
    {
      value: "63001",
      name: "ARMENIA",
      provinceCode: "63",
    },
    {
      value: "63111",
      name: "BUENAVISTA",
      provinceCode: "63",
    },
    {
      value: "63190",
      name: "CIRCASIA",
      provinceCode: "63",
    },
    {
      value: "63212",
      name: "Córdoba",
      provinceCode: "63",
    },
    {
      value: "63272",
      name: "FILANDIA",
      provinceCode: "63",
    },
    {
      value: "63401",
      name: "LA TEBAIDA",
      provinceCode: "63",
    },
    {
      value: "63470",
      name: "MONTENEGRO",
      provinceCode: "63",
    },
    {
      value: "63548",
      name: "PIJAO",
      provinceCode: "63",
    },
    {
      value: "63594",
      name: "QUIMBAYA",
      provinceCode: "63",
    },
    {
      value: "63690",
      name: "SALENTO",
      provinceCode: "63",
    },
    {
      value: "66001",
      name: "PEREIRA",
      provinceCode: "66",
    },
    {
      value: "66045",
      name: "APÍA",
      provinceCode: "66",
    },
    {
      value: "66075",
      name: "BALBOA",
      provinceCode: "66",
    },
    {
      value: "66170",
      name: "DOSQUEBRADAS",
      provinceCode: "66",
    },
    {
      value: "66318",
      name: "GUÁTICA",
      provinceCode: "66",
    },
    {
      value: "66383",
      name: "LA CELIA",
      provinceCode: "66",
    },
    {
      value: "66400",
      name: "LA VIRGINIA",
      provinceCode: "66",
    },
    {
      value: "66440",
      name: "MARSELLA",
      provinceCode: "66",
    },
    {
      value: "66456",
      name: "MISTRATÓ",
      provinceCode: "66",
    },
    {
      value: "66572",
      name: "PUEBLO RICO",
      provinceCode: "66",
    },
    {
      value: "66594",
      name: "QUINCHÍA",
      provinceCode: "66",
    },
    {
      value: "66687",
      name: "SANTUARIO",
      provinceCode: "66",
    },
    {
      value: "68001",
      name: "BUCARAMANGA",
      provinceCode: "68",
    },
    {
      value: "68013",
      name: "AGUADA",
      provinceCode: "68",
    },
    {
      value: "68020",
      name: "ALBANIA",
      provinceCode: "68",
    },
    {
      value: "68051",
      name: "ARATOCA",
      provinceCode: "68",
    },
    {
      value: "68077",
      name: "BARBOSA",
      provinceCode: "68",
    },
    {
      value: "68079",
      name: "BARICHARA",
      provinceCode: "68",
    },
    {
      value: "68081",
      name: "BARRANCABERMEJA",
      provinceCode: "68",
    },
    {
      value: "68092",
      name: "BETULIA",
      provinceCode: "68",
    },
    {
      value: "68101",
      name: "Bolívar",
      provinceCode: "68",
    },
    {
      value: "68121",
      name: "CABRERA",
      provinceCode: "68",
    },
    {
      value: "68132",
      name: "CALIFORNIA",
      provinceCode: "68",
    },
    {
      value: "68152",
      name: "CARCASÍ",
      provinceCode: "68",
    },
    {
      value: "68160",
      name: "CEPITÁ",
      provinceCode: "68",
    },
    {
      value: "68162",
      name: "CERRITO",
      provinceCode: "68",
    },
    {
      value: "68167",
      name: "CHARALÁ",
      provinceCode: "68",
    },
    {
      value: "68169",
      name: "CHARTA",
      provinceCode: "68",
    },
    {
      value: "68179",
      name: "CHIPATÁ",
      provinceCode: "68",
    },
    {
      value: "68190",
      name: "CIMITARRA",
      provinceCode: "68",
    },
    {
      value: "68207",
      name: "CONCEPCIÓN",
      provinceCode: "68",
    },
    {
      value: "68209",
      name: "CONFINES",
      provinceCode: "68",
    },
    {
      value: "68211",
      name: "CONTRATACIÓN",
      provinceCode: "68",
    },
    {
      value: "68217",
      name: "COROMORO",
      provinceCode: "68",
    },
    {
      value: "68229",
      name: "CURITÍ",
      provinceCode: "68",
    },
    {
      value: "68245",
      name: "EL GUACAMAYO",
      provinceCode: "68",
    },
    {
      value: "68255",
      name: "EL PLAYÓN",
      provinceCode: "68",
    },
    {
      value: "68264",
      name: "ENCINO",
      provinceCode: "68",
    },
    {
      value: "68266",
      name: "ENCISO",
      provinceCode: "68",
    },
    {
      value: "68271",
      name: "FLORIÁN",
      provinceCode: "68",
    },
    {
      value: "68276",
      name: "FLORIDABLANCA",
      provinceCode: "68",
    },
    {
      value: "68296",
      name: "GALÁN",
      provinceCode: "68",
    },
    {
      value: "68298",
      name: "GAMBITA",
      provinceCode: "68",
    },
    {
      value: "68307",
      name: "GIRÓN",
      provinceCode: "68",
    },
    {
      value: "68318",
      name: "GUACA",
      provinceCode: "68",
    },
    {
      value: "68320",
      name: "GUADALUPE",
      provinceCode: "68",
    },
    {
      value: "68322",
      name: "GUAPOTÁ",
      provinceCode: "68",
    },
    {
      value: "68324",
      name: "GUAVATÁ",
      provinceCode: "68",
    },
    {
      value: "68327",
      name: "GÜEPSA",
      provinceCode: "68",
    },
    {
      value: "68368",
      name: "JESÚS MARÍA",
      provinceCode: "68",
    },
    {
      value: "68370",
      name: "JORDÁN",
      provinceCode: "68",
    },
    {
      value: "68377",
      name: "LA BELLEZA",
      provinceCode: "68",
    },
    {
      value: "68385",
      name: "LANDÁZURI",
      provinceCode: "68",
    },
    {
      value: "68397",
      name: "LA PAZ",
      provinceCode: "68",
    },
    {
      value: "68406",
      name: "LEBRÍJA",
      provinceCode: "68",
    },
    {
      value: "68418",
      name: "LOS SANTOS",
      provinceCode: "68",
    },
    {
      value: "68425",
      name: "MACARAVITA",
      provinceCode: "68",
    },
    {
      value: "68432",
      name: "MÁLAGA",
      provinceCode: "68",
    },
    {
      value: "68444",
      name: "MATANZA",
      provinceCode: "68",
    },
    {
      value: "68464",
      name: "MOGOTES",
      provinceCode: "68",
    },
    {
      value: "68468",
      name: "MOLAGAVITA",
      provinceCode: "68",
    },
    {
      value: "68498",
      name: "OCAMONTE",
      provinceCode: "68",
    },
    {
      value: "68500",
      name: "OIBA",
      provinceCode: "68",
    },
    {
      value: "68502",
      name: "ONZAGA",
      provinceCode: "68",
    },
    {
      value: "68522",
      name: "PALMAR",
      provinceCode: "68",
    },
    {
      value: "68533",
      name: "PÁRAMO",
      provinceCode: "68",
    },
    {
      value: "68547",
      name: "PIEDECUESTA",
      provinceCode: "68",
    },
    {
      value: "68549",
      name: "PINCHOTE",
      provinceCode: "68",
    },
    {
      value: "68572",
      name: "PUENTE NACIONAL",
      provinceCode: "68",
    },
    {
      value: "68615",
      name: "RIONEGRO",
      provinceCode: "68",
    },
    {
      value: "68669",
      name: "SAN ANDRÉS",
      provinceCode: "68",
    },
    {
      value: "68679",
      name: "SAN GIL",
      provinceCode: "68",
    },
    {
      value: "68682",
      name: "SAN JOAQUÍN",
      provinceCode: "68",
    },
    {
      value: "68686",
      name: "SAN MIGUEL",
      provinceCode: "68",
    },
    {
      value: "68705",
      name: "SANTA BÁRBARA",
      provinceCode: "68",
    },
    {
      value: "68745",
      name: "SIMACOTA",
      provinceCode: "68",
    },
    {
      value: "68755",
      name: "SOCORRO",
      provinceCode: "68",
    },
    {
      value: "68770",
      name: "SUAITA",
      provinceCode: "68",
    },
    {
      value: "68773",
      name: "Sucre",
      provinceCode: "68",
    },
    {
      value: "68780",
      name: "SURATÁ",
      provinceCode: "68",
    },
    {
      value: "68820",
      name: "TONA",
      provinceCode: "68",
    },
    {
      value: "68861",
      name: "VÉLEZ",
      provinceCode: "68",
    },
    {
      value: "68867",
      name: "VETAS",
      provinceCode: "68",
    },
    {
      value: "68872",
      name: "VILLANUEVA",
      provinceCode: "68",
    },
    {
      value: "68895",
      name: "ZAPATOCA",
      provinceCode: "68",
    },
    {
      value: "70001",
      name: "SINCELEJO",
      provinceCode: "70",
    },
    {
      value: "70110",
      name: "BUENAVISTA",
      provinceCode: "70",
    },
    {
      value: "70124",
      name: "CAIMITO",
      provinceCode: "70",
    },
    {
      value: "70204",
      name: "COLOSO",
      provinceCode: "70",
    },
    {
      value: "70221",
      name: "COVEÑAS",
      provinceCode: "70",
    },
    {
      value: "70230",
      name: "CHALÁN",
      provinceCode: "70",
    },
    {
      value: "70233",
      name: "EL ROBLE",
      provinceCode: "70",
    },
    {
      value: "70235",
      name: "GALERAS",
      provinceCode: "70",
    },
    {
      value: "70265",
      name: "GUARANDA",
      provinceCode: "70",
    },
    {
      value: "70400",
      name: "LA UNIÓN",
      provinceCode: "70",
    },
    {
      value: "70418",
      name: "LOS PALMITOS",
      provinceCode: "70",
    },
    {
      value: "70429",
      name: "MAJAGUAL",
      provinceCode: "70",
    },
    {
      value: "70473",
      name: "MORROA",
      provinceCode: "70",
    },
    {
      value: "70508",
      name: "OVEJAS",
      provinceCode: "70",
    },
    {
      value: "70523",
      name: "PALMITO",
      provinceCode: "70",
    },
    {
      value: "70678",
      name: "SAN BENITO ABAD",
      provinceCode: "70",
    },
    {
      value: "70708",
      name: "SAN MARCOS",
      provinceCode: "70",
    },
    {
      value: "70713",
      name: "SAN ONOFRE",
      provinceCode: "70",
    },
    {
      value: "70717",
      name: "SAN PEDRO",
      provinceCode: "70",
    },
    {
      value: "70771",
      name: "Sucre",
      provinceCode: "70",
    },
    {
      value: "70823",
      name: "TOLÚ VIEJO",
      provinceCode: "70",
    },
    {
      value: "73024",
      name: "ALPUJARRA",
      provinceCode: "73",
    },
    {
      value: "73026",
      name: "ALVARADO",
      provinceCode: "73",
    },
    {
      value: "73030",
      name: "AMBALEMA",
      provinceCode: "73",
    },
    {
      value: "73055",
      name: "ARMERO",
      provinceCode: "73",
    },
    {
      value: "73067",
      name: "ATACO",
      provinceCode: "73",
    },
    {
      value: "73124",
      name: "CAJAMARCA",
      provinceCode: "73",
    },
    {
      value: "73168",
      name: "CHAPARRAL",
      provinceCode: "73",
    },
    {
      value: "73200",
      name: "COELLO",
      provinceCode: "73",
    },
    {
      value: "73217",
      name: "COYAIMA",
      provinceCode: "73",
    },
    {
      value: "73226",
      name: "CUNDAY",
      provinceCode: "73",
    },
    {
      value: "73236",
      name: "DOLORES",
      provinceCode: "73",
    },
    {
      value: "73268",
      name: "ESPINAL",
      provinceCode: "73",
    },
    {
      value: "73270",
      name: "FALAN",
      provinceCode: "73",
    },
    {
      value: "73275",
      name: "FLANDES",
      provinceCode: "73",
    },
    {
      value: "73283",
      name: "FRESNO",
      provinceCode: "73",
    },
    {
      value: "73319",
      name: "GUAMO",
      provinceCode: "73",
    },
    {
      value: "73347",
      name: "HERVEO",
      provinceCode: "73",
    },
    {
      value: "73349",
      name: "HONDA",
      provinceCode: "73",
    },
    {
      value: "73352",
      name: "ICONONZO",
      provinceCode: "73",
    },
    {
      value: "73443",
      name: "MARIQUITA",
      provinceCode: "73",
    },
    {
      value: "73449",
      name: "MELGAR",
      provinceCode: "73",
    },
    {
      value: "73461",
      name: "MURILLO",
      provinceCode: "73",
    },
    {
      value: "73483",
      name: "NATAGAIMA",
      provinceCode: "73",
    },
    {
      value: "73504",
      name: "ORTEGA",
      provinceCode: "73",
    },
    {
      value: "73520",
      name: "PALOCABILDO",
      provinceCode: "73",
    },
    {
      value: "73547",
      name: "PIEDRAS",
      provinceCode: "73",
    },
    {
      value: "73555",
      name: "PLANADAS",
      provinceCode: "73",
    },
    {
      value: "73563",
      name: "PRADO",
      provinceCode: "73",
    },
    {
      value: "73585",
      name: "PURIFICACIÓN",
      provinceCode: "73",
    },
    {
      value: "73616",
      name: "RIO BLANCO",
      provinceCode: "73",
    },
    {
      value: "73622",
      name: "RONCESVALLES",
      provinceCode: "73",
    },
    {
      value: "73624",
      name: "ROVIRA",
      provinceCode: "73",
    },
    {
      value: "73671",
      name: "SALDAÑA",
      provinceCode: "73",
    },
    {
      value: "73686",
      name: "SANTA ISABEL",
      provinceCode: "73",
    },
    {
      value: "73861",
      name: "VENADILLO",
      provinceCode: "73",
    },
    {
      value: "73870",
      name: "VILLAHERMOSA",
      provinceCode: "73",
    },
    {
      value: "73873",
      name: "VILLARRICA",
      provinceCode: "73",
    },
    {
      value: "81065",
      name: "ARAUQUITA",
      provinceCode: "81",
    },
    {
      value: "81220",
      name: "CRAVO NORTE",
      provinceCode: "81",
    },
    {
      value: "81300",
      name: "FORTUL",
      provinceCode: "81",
    },
    {
      value: "81591",
      name: "PUERTO RONDÓN",
      provinceCode: "81",
    },
    {
      value: "81736",
      name: "SARAVENA",
      provinceCode: "81",
    },
    {
      value: "81794",
      name: "TAME",
      provinceCode: "81",
    },
    {
      value: "81001",
      name: "ARAUCA",
      provinceCode: "81",
    },
    {
      value: "85001",
      name: "YOPAL",
      provinceCode: "85",
    },
    {
      value: "85010",
      name: "AGUAZUL",
      provinceCode: "85",
    },
    {
      value: "85015",
      name: "CHÁMEZA",
      provinceCode: "85",
    },
    {
      value: "85125",
      name: "HATO COROZAL",
      provinceCode: "85",
    },
    {
      value: "85136",
      name: "LA SALINA",
      provinceCode: "85",
    },
    {
      value: "85162",
      name: "MONTERREY",
      provinceCode: "85",
    },
    {
      value: "85263",
      name: "PORE",
      provinceCode: "85",
    },
    {
      value: "85279",
      name: "RECETOR",
      provinceCode: "85",
    },
    {
      value: "85300",
      name: "SABANALARGA",
      provinceCode: "85",
    },
    {
      value: "85315",
      name: "SÁCAMA",
      provinceCode: "85",
    },
    {
      value: "85410",
      name: "TAURAMENA",
      provinceCode: "85",
    },
    {
      value: "85430",
      name: "TRINIDAD",
      provinceCode: "85",
    },
    {
      value: "85440",
      name: "VILLANUEVA",
      provinceCode: "85",
    },
    {
      value: "86001",
      name: "MOCOA",
      provinceCode: "86",
    },
    {
      value: "86219",
      name: "COLÓN",
      provinceCode: "86",
    },
    {
      value: "86320",
      name: "ORITO",
      provinceCode: "86",
    },
    {
      value: "86569",
      name: "PUERTO CAICEDO",
      provinceCode: "86",
    },
    {
      value: "86571",
      name: "PUERTO GUZMÁN",
      provinceCode: "86",
    },
    {
      value: "86573",
      name: "LEGUÍZAMO",
      provinceCode: "86",
    },
    {
      value: "86749",
      name: "SIBUNDOY",
      provinceCode: "86",
    },
    {
      value: "86755",
      name: "SAN FRANCISCO",
      provinceCode: "86",
    },
    {
      value: "86757",
      name: "SAN MIGUEL",
      provinceCode: "86",
    },
    {
      value: "86760",
      name: "SANTIAGO",
      provinceCode: "86",
    },
    {
      value: "91001",
      name: "LETICIA",
      provinceCode: "91",
    },
    {
      value: "91263",
      name: "EL ENCANTO",
      provinceCode: "91",
    },
    {
      value: "91405",
      name: "LA CHORRERA",
      provinceCode: "91",
    },
    {
      value: "91407",
      name: "LA PEDRERA",
      provinceCode: "91",
    },
    {
      value: "91430",
      name: "LA VICTORIA",
      provinceCode: "91",
    },
    {
      value: "91536",
      name: "PUERTO ARICA",
      provinceCode: "91",
    },
    {
      value: "91540",
      name: "PUERTO NARIÑO",
      provinceCode: "91",
    },
    {
      value: "91669",
      name: "PUERTO SANTANDER",
      provinceCode: "91",
    },
    {
      value: "91798",
      name: "TARAPACÁ",
      provinceCode: "91",
    },
    {
      value: "94001",
      name: "INÍRIDA",
      provinceCode: "94",
    },
    {
      value: "94343",
      name: "BARRANCO MINAS",
      provinceCode: "94",
    },
    {
      value: "94663",
      name: "MAPIRIPANA",
      provinceCode: "94",
    },
    {
      value: "94883",
      name: "SAN FELIPE",
      provinceCode: "94",
    },
    {
      value: "94884",
      name: "PUERTO COLOMBIA",
      provinceCode: "94",
    },
    {
      value: "94885",
      name: "LA GUADALUPE",
      provinceCode: "94",
    },
    {
      value: "94886",
      name: "CACAHUAL",
      provinceCode: "94",
    },
    {
      value: "94887",
      name: "PANA PANA",
      provinceCode: "94",
    },
    {
      value: "94888",
      name: "MORICHAL",
      provinceCode: "94",
    },
    {
      value: "97001",
      name: "MITÚ",
      provinceCode: "97",
    },
    {
      value: "97161",
      name: "CARURU",
      provinceCode: "97",
    },
    {
      value: "97511",
      name: "PACOA",
      provinceCode: "97",
    },
    {
      value: "97666",
      name: "TARAIRA",
      provinceCode: "97",
    },
    {
      value: "97777",
      name: "PAPUNAUA",
      provinceCode: "97",
    },
    {
      value: "99001",
      name: "PUERTO CARREÑO",
      provinceCode: "99",
    },
    {
      value: "99524",
      name: "LA PRIMAVERA",
      provinceCode: "99",
    },
    {
      value: "99624",
      name: "SANTA ROSALÍA",
      provinceCode: "99",
    },
    {
      value: "99773",
      name: "CUMARIBO",
      provinceCode: "99",
    },
    {
      value: "18610",
      name: "SAN JOSÉ DEL FRAGUA",
      provinceCode: "18",
    },
    {
      value: "50110",
      name: "BARRANCA DE UPÍA",
      provinceCode: "50",
    },
    {
      value: "68524",
      name: "PALMAS DEL SOCORRO",
      provinceCode: "68",
    },
    {
      value: "25662",
      name: "SAN JUAN DE RÍO SECO",
      provinceCode: "25",
    },
    {
      value: "08372",
      name: "JUAN DE ACOSTA",
      provinceCode: "08",
    },
    {
      value: "50287",
      name: "FUENTE DE ORO",
      provinceCode: "50",
    },
    {
      value: "85325",
      name: "SAN LUIS DE GACENO",
      provinceCode: "85",
    },
    {
      value: "27250",
      name: "EL LITORAL DEL SAN JUAN",
      provinceCode: "27",
    },
    {
      value: "25843",
      name: "VILLA DE SAN DIEGO DE UBATE",
      provinceCode: "25",
    },
    {
      value: "13074",
      name: "BARRANCO DE LOBA",
      provinceCode: "13",
    },
    {
      value: "15816",
      name: "TOGÜÍ",
      provinceCode: "15",
    },
    {
      value: "13688",
      name: "SANTA ROSA DEL SUR",
      provinceCode: "13",
    },
    {
      value: "27135",
      name: "EL CANTÓN DEL SAN PABLO",
      provinceCode: "27",
    },
    {
      value: "15407",
      name: "VILLA DE LEYVA",
      provinceCode: "15",
    },
    {
      value: "47692",
      name: "SAN SEBASTIÁN DE BUENAVISTA",
      provinceCode: "47",
    },
    {
      value: "15537",
      name: "PAZ DE RÍO",
      provinceCode: "15",
    },
    {
      value: "13300",
      name: "HATILLO DE LOBA",
      provinceCode: "13",
    },
    {
      value: "47660",
      name: "SABANAS DE SAN ANGEL",
      provinceCode: "47",
    },
    {
      value: "95015",
      name: "CALAMAR",
      provinceCode: "95",
    },
    {
      value: "20614",
      name: "RÍO DE ORO",
      provinceCode: "20",
    },
    {
      value: "05665",
      name: "SAN PEDRO DE URABA",
      provinceCode: "05",
    },
    {
      value: "95001",
      name: "SAN JOSÉ DEL GUAVIARE",
      provinceCode: "95",
    },
    {
      value: "15693",
      name: "SANTA ROSA DE VITERBO",
      provinceCode: "15",
    },
    {
      value: "19698",
      name: "SANTANDER DE QUILICHAO",
      provinceCode: "19",
    },
    {
      value: "95200",
      name: "MIRAFLORES",
      provinceCode: "95",
    },
    {
      value: "05042",
      name: "SANTAFÉ DE ANTIOQUIA",
      provinceCode: "05",
    },
    {
      value: "50680",
      name: "SAN CARLOS DE GUAROA",
      provinceCode: "50",
    },
    {
      value: "08520",
      name: "PALMAR DE VARELA",
      provinceCode: "08",
    },
    {
      value: "05686",
      name: "SANTA ROSA DE OSOS",
      provinceCode: "05",
    },
    {
      value: "05647",
      name: "SAN ANDRÉS DE CUERQUÍA",
      provinceCode: "05",
    },
    {
      value: "73854",
      name: "VALLE DE SAN JUAN",
      provinceCode: "73",
    },
    {
      value: "68689",
      name: "SAN VICENTE DE CHUCURÍ",
      provinceCode: "68",
    },
    {
      value: "68684",
      name: "SAN JOSÉ DE MIRANDA",
      provinceCode: "68",
    },
    {
      value: "88564",
      name: "PROVIDENCIA",
      provinceCode: "88",
    },
    {
      value: "66682",
      name: "SANTA ROSA DE CABAL",
      provinceCode: "66",
    },
    {
      value: "25328",
      name: "GUAYABAL DE SIQUIMA",
      provinceCode: "25",
    },
    {
      value: "18094",
      name: "BELÉN DE LOS ANDAQUIES",
      provinceCode: "18",
    },
    {
      value: "85250",
      name: "PAZ DE ARIPORO",
      provinceCode: "85",
    },
    {
      value: "68720",
      name: "SANTA HELENA DEL OPÓN",
      provinceCode: "68",
    },
    {
      value: "15681",
      name: "SAN PABLO DE BORBUR",
      provinceCode: "15",
    },
    {
      value: "44420",
      name: "LA JAGUA DEL PILAR",
      provinceCode: "44",
    },
    {
      value: "20400",
      name: "LA JAGUA DE IBIRICO",
      provinceCode: "20",
    },
    {
      value: "70742",
      name: "SAN LUIS DE SINCÉ",
      provinceCode: "70",
    },
    {
      value: "15667",
      name: "SAN LUIS DE GACENO",
      provinceCode: "15",
    },
    {
      value: "13244",
      name: "EL CARMEN DE BOLÍVAR",
      provinceCode: "13",
    },
    {
      value: "27245",
      name: "EL CARMEN DE ATRATO",
      provinceCode: "27",
    },
    {
      value: "70702",
      name: "SAN JUAN DE BETULIA",
      provinceCode: "70",
    },
    {
      value: "47545",
      name: "PIJIÑO DEL CARMEN",
      provinceCode: "47",
    },
    {
      value: "05873",
      name: "VIGÍA DEL FUERTE",
      provinceCode: "05",
    },
    {
      value: "13667",
      name: "SAN MARTÍN DE LOBA",
      provinceCode: "13",
    },
    {
      value: "13030",
      name: "ALTOS DEL ROSARIO",
      provinceCode: "13",
    },
    {
      value: "73148",
      name: "CARMEN DE APICALA",
      provinceCode: "73",
    },
    {
      value: "25645",
      name: "SAN ANTONIO DEL TEQUENDAMA",
      provinceCode: "25",
    },
    {
      value: "68655",
      name: "SABANA DE TORRES",
      provinceCode: "68",
    },
    {
      value: "95025",
      name: "EL RETORNO",
      provinceCode: "95",
    },
    {
      value: "23682",
      name: "SAN JOSÉ DE URÉ",
      provinceCode: "23",
    },
    {
      value: "52694",
      name: "SAN PEDRO DE CARTAGO",
      provinceCode: "52",
    },
    {
      value: "08137",
      name: "CAMPO DE LA CRUZ",
      provinceCode: "08",
    },
    {
      value: "50683",
      name: "SAN JUAN DE ARAMA",
      provinceCode: "50",
    },
    {
      value: "05658",
      name: "SAN JOSÉ DE LA MONTAÑA",
      provinceCode: "05",
    },
    {
      value: "18150",
      name: "CARTAGENA DEL CHAIRÁ",
      provinceCode: "18",
    },
    {
      value: "27660",
      name: "SAN JOSÉ DEL PALMAR",
      provinceCode: "27",
    },
    {
      value: "25001",
      name: "AGUA DE DIOS",
      provinceCode: "25",
    },
    {
      value: "13655",
      name: "SAN JACINTO DEL CAUCA",
      provinceCode: "13",
    },
    {
      value: "41668",
      name: "SAN AGUSTÍN",
      provinceCode: "41",
    },
    {
      value: "52258",
      name: "EL TABLÓN DE GÓMEZ",
      provinceCode: "52",
    },
    {
      value: "88001",
      name: "SAN ANDRÉS",
      provinceCode: "88",
    },
    {
      value: "15664",
      name: "SAN JOSÉ DE PARE",
      provinceCode: "15",
    },
    {
      value: "86865",
      name: "VALLE DE GUAMEZ",
      provinceCode: "86",
    },
    {
      value: "13670",
      name: "SAN PABLO DE BORBUR",
      provinceCode: "13",
    },
    {
      value: "70820",
      name: "SANTIAGO DE TOLÚ",
      provinceCode: "70",
    },
    {
      value: "11001",
      name: "BOGOTÁ D.C.",
      provinceCode: "11",
    },
    {
      value: "25154",
      name: "CARMEN DE CARUPA",
      provinceCode: "25",
    },
    {
      value: "23189",
      name: "CIÉNAGA DE ORO",
      provinceCode: "23",
    },
    {
      value: "05659",
      name: "SAN JUAN DE URABÁ",
      provinceCode: "05",
    },
    {
      value: "44650",
      name: "SAN JUAN DEL CESAR",
      provinceCode: "44",
    },
    {
      value: "68235",
      name: "EL CARMEN DE CHUCURÍ",
      provinceCode: "68",
    },
    {
      value: "05148",
      name: "EL CARMEN DE VIBORAL",
      provinceCode: "05",
    },
    {
      value: "66088",
      name: "BELÉN DE UMBRÍA",
      provinceCode: "66",
    },
    {
      value: "27086",
      name: "BELÉN DE BAJIRA",
      provinceCode: "27",
    },
    {
      value: "68855",
      name: "VALLE DE SAN JOSÉ",
      provinceCode: "68",
    },
    {
      value: "73678",
      name: "SAN LUIS",
      provinceCode: "73",
    },
    {
      value: "15676",
      name: "SAN MIGUEL DE SEMA",
      provinceCode: "15",
    },
    {
      value: "73675",
      name: "SAN ANTONIO",
      provinceCode: "73",
    },
    {
      value: "68673",
      name: "SAN BENITO",
      provinceCode: "68",
    },
    {
      value: "25862",
      name: "VERGARA",
      provinceCode: "25",
    },
    {
      value: "23678",
      name: "SAN CARLOS",
      provinceCode: "23",
    },
    {
      value: "91530",
      name: "PUERTO ALEGRÍA",
      provinceCode: "91",
    },
    {
      value: "68344",
      name: "HATO",
      provinceCode: "68",
    },
    {
      value: "13654",
      name: "SAN JACINTO",
      provinceCode: "13",
    },
    {
      value: "19693",
      name: "SAN SEBASTIÁN",
      provinceCode: "19",
    },
    {
      value: "05649",
      name: "SAN CARLOS",
      provinceCode: "05",
    },
    {
      value: "15837",
      name: "TUTA",
      provinceCode: "15",
    },
    {
      value: "54743",
      name: "SILOS",
      provinceCode: "54",
    },
    {
      value: "54125",
      name: "CÁCOTA",
      provinceCode: "54",
    },
    {
      value: "76250",
      name: "EL DOVIO",
      provinceCode: "76",
    },
    {
      value: "54820",
      name: "TOLEDO",
      provinceCode: "54",
    },
    {
      value: "76622",
      name: "ROLDANILLO",
      provinceCode: "76",
    },
    {
      value: "54480",
      name: "MUTISCUA",
      provinceCode: "54",
    },
    {
      value: "76054",
      name: "ARGELIA",
      provinceCode: "76",
    },
    {
      value: "54261",
      name: "EL ZULIA",
      provinceCode: "54",
    },
    {
      value: "54660",
      name: "SALAZAR",
      provinceCode: "54",
    },
    {
      value: "76736",
      name: "SEVILLA",
      provinceCode: "76",
    },
    {
      value: "76895",
      name: "ZARZAL",
      provinceCode: "76",
    },
    {
      value: "54223",
      name: "CUCUTILLA",
      provinceCode: "54",
    },
    {
      value: "76248",
      name: "EL CERRITO",
      provinceCode: "76",
    },
    {
      value: "76147",
      name: "CARTAGO",
      provinceCode: "76",
    },
    {
      value: "76122",
      name: "CAICEDONIA",
      provinceCode: "76",
    },
    {
      value: "54553",
      name: "PUERTO SANTANDER",
      provinceCode: "54",
    },
    {
      value: "54313",
      name: "GRAMALOTE",
      provinceCode: "54",
    },
    {
      value: "76246",
      name: "EL CAIRO",
      provinceCode: "76",
    },
    {
      value: "54250",
      name: "EL TARRA",
      provinceCode: "54",
    },
    {
      value: "76400",
      name: "LA UNIÓN",
      provinceCode: "76",
    },
    {
      value: "76606",
      name: "RESTREPO",
      provinceCode: "76",
    },
    {
      value: "54800",
      name: "TEORAMA",
      provinceCode: "54",
    },
    {
      value: "76233",
      name: "DAGUA",
      provinceCode: "76",
    },
    {
      value: "54051",
      name: "ARBOLEDAS",
      provinceCode: "54",
    },
    {
      value: "76318",
      name: "GUACARÍ",
      provinceCode: "76",
    },
    {
      value: "54418",
      name: "LOURDES",
      provinceCode: "54",
    },
    {
      value: "76041",
      name: "ANSERMANUEVO",
      provinceCode: "76",
    },
    {
      value: "54099",
      name: "BOCHALEMA",
      provinceCode: "54",
    },
    {
      value: "76113",
      name: "BUGALAGRANDE",
      provinceCode: "76",
    },
    {
      value: "54206",
      name: "CONVENCIÓN",
      provinceCode: "54",
    },
    {
      value: "54344",
      name: "HACARÍ",
      provinceCode: "54",
    },
    {
      value: "76403",
      name: "LA VICTORIA",
      provinceCode: "76",
    },
    {
      value: "54347",
      name: "HERRÁN",
      provinceCode: "54",
    },
    {
      value: "76306",
      name: "GINEBRA",
      provinceCode: "76",
    },
    {
      value: "76892",
      name: "YUMBO",
      provinceCode: "76",
    },
    {
      value: "76497",
      name: "OBANDO",
      provinceCode: "76",
    },
    {
      value: "54810",
      name: "TIBÚ",
      provinceCode: "54",
    },
    {
      value: "54673",
      name: "SAN CAYETANO",
      provinceCode: "54",
    },
    {
      value: "54670",
      name: "SAN CALIXTO",
      provinceCode: "54",
    },
    {
      value: "76100",
      name: "Bolívar",
      provinceCode: "76",
    },
    {
      value: "54398",
      name: "LA PLAYA",
      provinceCode: "54",
    },
    {
      value: "76001",
      name: "CALI",
      provinceCode: "76",
    },
    {
      value: "76670",
      name: "SAN PEDRO",
      provinceCode: "76",
    },
    {
      value: "76111",
      name: "GUADALAJARA DE BUGA",
      provinceCode: "76",
    },
    {
      value: "54172",
      name: "CHINÁCOTA",
      provinceCode: "54",
    },
    {
      value: "54599",
      name: "RAGONVALIA",
      provinceCode: "54",
    },
    {
      value: "54385",
      name: "LA ESPERANZA",
      provinceCode: "54",
    },
    {
      value: "54874",
      name: "VILLA DEL ROSARIO",
      provinceCode: "54",
    },
    {
      value: "54174",
      name: "CHITAGÁ",
      provinceCode: "54",
    },
    {
      value: "76126",
      name: "CALIMA",
      provinceCode: "76",
    },
    {
      value: "54720",
      name: "SARDINATA",
      provinceCode: "54",
    },
    {
      value: "76036",
      name: "ANDALUCÍA",
      provinceCode: "76",
    },
    {
      value: "76563",
      name: "PRADERA",
      provinceCode: "76",
    },
    {
      value: "54003",
      name: "ABREGO",
      provinceCode: "54",
    },
    {
      value: "54405",
      name: "LOS PATIOS",
      provinceCode: "54",
    },
    {
      value: "54498",
      name: "OCAÑA",
      provinceCode: "54",
    },
    {
      value: "54109",
      name: "BUCARASICA",
      provinceCode: "54",
    },
    {
      value: "76890",
      name: "YOTOCO",
      provinceCode: "76",
    },
    {
      value: "76520",
      name: "PALMIRA",
      provinceCode: "76",
    },
    {
      value: "76616",
      name: "RIOFRÍO",
      provinceCode: "76",
    },
    {
      value: "54680",
      name: "SANTIAGO",
      provinceCode: "54",
    },
    {
      value: "76020",
      name: "ALCALÁ",
      provinceCode: "76",
    },
    {
      value: "76863",
      name: "VERSALLES",
      provinceCode: "76",
    },
    {
      value: "54377",
      name: "LABATECA",
      provinceCode: "54",
    },
    {
      value: "54128",
      name: "CACHIRÁ",
      provinceCode: "54",
    },
    {
      value: "54871",
      name: "VILLA CARO",
      provinceCode: "54",
    },
    {
      value: "54239",
      name: "DURANIA",
      provinceCode: "54",
    },
    {
      value: "76243",
      name: "EL ÁGUILA",
      provinceCode: "76",
    },
    {
      value: "76823",
      name: "TORO",
      provinceCode: "76",
    },
    {
      value: "76130",
      name: "CANDELARIA",
      provinceCode: "76",
    },
    {
      value: "76377",
      name: "LA CUMBRE",
      provinceCode: "76",
    },
    {
      value: "76845",
      name: "ULLOA",
      provinceCode: "76",
    },
    {
      value: "76828",
      name: "TRUJILLO",
      provinceCode: "76",
    },
    {
      value: "76869",
      name: "VIJES",
      provinceCode: "76",
    },
  ],
};
