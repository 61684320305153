import React, { FC } from "react";
import ButtonBase from "@material-ui/core/ButtonBase";
import kushkiLogo from "../../../../assets/imagen/logos/kushki.svg";
import Chip from "@material-ui/core/Chip";
import {
  Avatar,
  Box,
  Divider,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { countryFlags } from "../../../../infrastructure/constants/CountryFlags";
import { RoutesEnum } from "../../../../infrastructure/constants/RoutesEnum";
import { useConsoleLayoutContext } from "../../context/useConsoleLayoutContext";
import { isEmpty } from "lodash";
import { useLocation } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  headerLeftContainer: {
    marginLeft: `${theme.spacing(2)}px !important`,
    display: "flex !important",
    alignItems: "center !important",
    overflow: "hidden !important",
    [theme.breakpoints.down("sm")]: {
      marginRight: `${theme.spacing(0.5)}px !important`,
    },
  },
  headerIcon: {
    height: `${theme.spacing(4)}px !important`,
    width: "auto !important",
    marginRight: `${theme.spacing(3)}px !important`,
    [theme.breakpoints.down("sm")]: {
      height: `${theme.spacing(3)}px !important`,
      marginRight: `${theme.spacing(1.5)}px !important`,
    },
  },
  headerChipModeTest: {
    marginRight: `${theme.spacing(3)}px !important`,
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: "#FFFFFF !important",
    borderRadius: `${theme.spacing(0.5)}px !important`,
    fontSize: "14px !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px !important",
      marginRight: `${theme.spacing(1.5)}px !important`,
    },
  },
  headerDivider: {
    width: "2px !important",
    height: `${theme.spacing(5)}px !important`,
    marginRight: `${theme.spacing(3)}px !important`,
    backgroundColor: "#E2E8F0 !important",
    [theme.breakpoints.down("sm")]: {
      marginRight: `${theme.spacing(1.5)}px !important`,
    },
  },
  headerAvatarCountry: {
    width: `${theme.spacing(3)}px !important`,
    height: `${theme.spacing(3)}px !important`,
    marginRight: `${theme.spacing(1)}px !important`,
  },
  headerTitleCountry: {
    maxWidth: `${theme.spacing(35)}px !important`,
    whiteSpace: "nowrap !important" as any,
    textOverflow: "ellipsis !important",
    overflow: "hidden !important",
    color: `${theme.palette.primary.main} !important`,
    fontSize: `${theme.spacing(2.5)}px !important`,
    [theme.breakpoints.down("sm")]: {
      fontSize: `${theme.spacing(2)}px !important`,
    },
  },
}));
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
export const LeftHeaderContainer: FC = () => {
  const classes = useStyles();
  const query = useQuery();

  const publicMerchantId: string | null = query.get("publicMerchantId");
  const localStorageData = localStorage.getItem("merchantBasicInformation");

  let merchantData = {
    country: "",
    merchantName: "",
  };
  if (!!localStorageData && publicMerchantId != null) {
    merchantData = JSON.parse(localStorageData);
  }

  const {
    isProduction,
    merchantInfo: {
      country = merchantData.country,
      name = merchantData.merchantName,
    },
  } = useConsoleLayoutContext();
  return (
    <Box className={classes.headerLeftContainer}>
      <ButtonBase onClick={() => (window.location.href = RoutesEnum.DASHBOARD)}>
        <img src={kushkiLogo} alt="Logo" className={classes.headerIcon} />
      </ButtonBase>
      {!isProduction && (
        <Chip
          className={classes.headerChipModeTest}
          size="small"
          label="Modo de Prueba"
        />
      )}
      {!isEmpty(country) && !isEmpty(name) && (
        <>
          <Divider
            orientation="vertical"
            flexItem
            className={classes.headerDivider}
          />
          <Avatar
            src={countryFlags[country]}
            className={classes.headerAvatarCountry}
          />
          <Typography className={classes.headerTitleCountry}>{name}</Typography>
        </>
      )}
    </Box>
  );
};
export default LeftHeaderContainer;
