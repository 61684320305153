enum paths {
  billingMerchantInvoice = "/create-merchant-reseller/billing-merchant/invoice",
  createMerchant = "/create-merchant-reseller",
  editMerchant = "/create-merchant-reseller/",
  createMerchantDetails = "/create-merchant-reseller/details",
  credentials = "/create-merchant-reseller/credentials",
}

export const InvalidTabModalPaths = [
  paths.billingMerchantInvoice,
  paths.createMerchant,
  paths.createMerchantDetails,
  paths.editMerchant,
];

export const PartialInvalidTabModalPaths = [paths.credentials];
