import React, { FC, useRef, useState } from "react";
import { Box, makeStyles, Menu } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import RolMenuButton from "../../../commons/RolMenuButton/RolMenuButton";
import { useConsoleLayoutContext } from "../../context/useConsoleLayoutContext";
import LogoutListItem from "../../../commons/LogoutListItem/LogoutListItem";
const useStyles = makeStyles((theme) => ({
  headerRightContainer: {
    marginRight: `${theme.spacing(2)}px !important`,
    display: "flex !important",
    alignItems: "center !important",
  },
  headerChipRole: {
    marginRight: `${theme.spacing(2)}px !important`,
    backgroundColor: `${theme.palette.primary.light} !important`,
    color: "#FFFFFF !important",
    borderRadius: `${theme.spacing(0.5)}px !important`,
  },
}));
export const RightHeaderContainer: FC = () => {
  const classes = useStyles();
  const ref = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState<boolean>(false);
  const { isAdmin } = useConsoleLayoutContext();
  return (
    <Box className={classes.headerRightContainer}>
      <Chip
        className={classes.headerChipRole}
        size="small"
        label={isAdmin ? "Admin" : "Usuario"}
      />
      <RolMenuButton
        handlerClick={() => setOpen(true)}
        myRef={ref}
        open={open}
      />
      <Menu
        id="simple-menu"
        anchorEl={ref.current}
        keepMounted
        open={open}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        elevation={1}
        getContentAnchorEl={null}
      >
        <LogoutListItem />
      </Menu>
    </Box>
  );
};
export default RightHeaderContainer;
