import { Color } from "@material-ui/lab/Alert";
import { SnackbarAlertEvent } from "components/commons/Snackbar/SnackBarAlert.interfaces";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

export const useConsoleLayout = () => {
  const [snackbarIsOpen, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackBarMessage] = useState("");
  const [snackbarType, setSnackbarType] = useState<Color>("success");
  const history = useHistory();

  const handleCloseSnackbar = () => setOpenSnackbar(false);

  useEffect(() => {
    window.addEventListener("open_snackbar", handleOpenSnackbarEvent);

    return () => {
      window.removeEventListener("open_snackbar", handleOpenSnackbarEvent);
    };
  }, []);

  const handleOpenSnackbarEvent = (event: Event) => {
    const snackbarEvent = event as SnackbarAlertEvent;

    setOpenSnackbar(true);
    setSnackBarMessage(snackbarEvent.detail.message);
    setSnackbarType(snackbarEvent.detail.type);

    if (snackbarEvent.detail.pathToRedirect) {
      history.push(snackbarEvent.detail.pathToRedirect);
    }
  };

  return {
    functions: {
      handleCloseSnackbar,
    },
    values: {
      snackbarIsOpen,
      snackbarMessage,
      snackbarType,
    },
  };
};
