import { IKushkiMenu } from "../interfaces/IKushkiMenu";
import { ReactComponent as HomeIcon } from "../../assets/imagen/icons/home.svg";
import { ReactComponent as BillingIcon } from "../../assets/imagen/icons/billing.svg";
import { ReactComponent as MerchantIcon } from "../../assets/imagen/icons/merchant.svg";
import { ReactComponent as CenterIcon } from "../../assets/imagen/icons/center.svg";
import { ReactComponent as InformationIcon } from "../../assets/imagen/icons/information.svg";
import { ReactComponent as WalletIcon } from "../../assets/imagen/icons/wallet.svg";
import { ReactComponent as DocumentIcon } from "../../assets/imagen/icons/documents.svg";
import { ReactComponent as TransactionsIcon } from "../../assets/imagen/icons/transactions.svg";
import { ReactComponent as UsersIcon } from "../../assets/imagen/icons/users.svg";
import { ReactComponent as DevsIcon } from "../../assets/imagen/icons/devs.svg";
import { RoutesEnum } from "./RoutesEnum";

const HOME: IKushkiMenu = {
  title: "Inicio",
  icon: HomeIcon,
  routes: RoutesEnum.DASHBOARD,
  priority: 1,
};

const MERCHANT: IKushkiMenu = {
  title: "Comercios",
  icon: MerchantIcon,
  routes: RoutesEnum.MERCHANT,
  priority: 2,
};

const DOCUMENTS: IKushkiMenu = {
  icon: DocumentIcon,
  title: "Emisión de Documentos",
  routes: RoutesEnum.EMISSION,
  priority: 3,
};

const TRANSACTIONS: IKushkiMenu = {
  title: "Histórico",
  icon: TransactionsIcon,
  routes: RoutesEnum.TRANSACTIONS,
  priority: 4,
};

const WALLET: IKushkiMenu = {
  title: "Estado de cuenta",
  icon: WalletIcon,
  routes: RoutesEnum.WALLET,
  priority: 5,
};

const BILLING: IKushkiMenu = {
  title: "Facturación",
  icon: BillingIcon,
  routes: RoutesEnum.MERCHANT_BILLING,
  priority: 6,
};

const USERS: IKushkiMenu = {
  title: "Usuarios",
  icon: UsersIcon,
  routes: RoutesEnum.USERS,
  priority: 7,
};

const INFORMATION: IKushkiMenu = {
  title: "Información",
  icon: InformationIcon,
  routes: RoutesEnum.INFORMATION,
  priority: 8,
};

const COST_CENTER: IKushkiMenu = {
  title: "Centro de costos",
  icon: CenterIcon,
  routes: RoutesEnum.COST_CENTER,
  priority: 9,
};

const DEVS: IKushkiMenu = {
  title: "Desarrolladores",
  icon: DevsIcon,
  routes: RoutesEnum.DEVS,
  priority: 10,
};

export const MENU_ADMIN: IKushkiMenu[] = [
  HOME,
  MERCHANT,
  COST_CENTER,
  TRANSACTIONS,
  WALLET,
  BILLING,
  USERS,
  INFORMATION,
];

export const MENU_USER_MASTER: IKushkiMenu[] = [
  HOME,
  COST_CENTER,
  DOCUMENTS,
  TRANSACTIONS,
  WALLET,
  BILLING,
  USERS,
  DEVS,
];

export const MENU_USER_READER: IKushkiMenu[] = [
  HOME,
  TRANSACTIONS,
  WALLET,
  BILLING,
];

export const MENU_USER_EMISSION: IKushkiMenu[] = [
  HOME,
  DOCUMENTS,
  TRANSACTIONS,
  WALLET,
  BILLING,
];
