/* eslint-disable */
import { appsToImport } from "../../shared/appsTo";

export interface IInjectableScripts {
  script: string;
  type?: string;
  productionOnly?: boolean;
}

export const injectableScripts: IInjectableScripts[] = [
  { script: `{"imports": {${appsToImport}}}`, type: "systemjs-importmap" },
];
