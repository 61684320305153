import { Avatar, makeStyles, Typography } from "@material-ui/core";
import { ChevronDown, ChevronUp } from "react-feather";
import ButtonBase from "@material-ui/core/ButtonBase";
import React, { FC, RefObject } from "react";
import { useConsoleLayoutContext } from "../../ConsoleLayout/context/useConsoleLayoutContext";

const useStyles = makeStyles((theme) => ({
  headerAvatarUser: {
    backgroundColor: `${theme.palette.primary.light} !important`,
    color: "#FFFFFF !important",
    fontSize: `${theme.spacing(2)}px !important`,
    marginRight: `${theme.spacing(1)}px !important`,
    textTransform: "uppercase !important" as any,
  },
  headerTitleUser: {
    fontSize: "14px !important",
    color: `${theme.palette.primary.main} !important`,
    marginRight: `${theme.spacing(1.5)}px !important`,
  },
  headerChevronIcon: {
    color: "#293036 !important",
    marginRight: `${theme.spacing(2.5)}px !important`,
  },
}));

export interface IRolMenuButtonProps {
  handlerClick: () => void;
  open: boolean;
  myRef?: RefObject<HTMLButtonElement>;
}

export const RolMenuButton: FC<IRolMenuButtonProps> = ({
  handlerClick,
  myRef,
  open,
}: IRolMenuButtonProps) => {
  const classes = useStyles();
  const { fullName, initialsName } = useConsoleLayoutContext();

  return (
    <ButtonBase onClick={() => handlerClick()} ref={myRef}>
      <Avatar className={classes.headerAvatarUser}>{initialsName}</Avatar>
      <Typography className={classes.headerTitleUser}>{fullName}</Typography>
      {open ? (
        <ChevronUp className={classes.headerChevronIcon} />
      ) : (
        <ChevronDown className={classes.headerChevronIcon} />
      )}
    </ButtonBase>
  );
};

export default RolMenuButton;
