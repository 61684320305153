import React, { FC, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import SideBar from "./SideBar/SideBar";
import SectionSpa from "./SectionSpa/SectionSpa";
import { useConsoleLayoutContext } from "../context/useConsoleLayoutContext";
import { useLocation } from "react-router-dom";
import {
  InvalidTabModalPaths,
  PartialInvalidTabModalPaths,
} from "../../../infrastructure/constants/InvalidTabModalPaths";
import { MerchantResume, ResumeModal } from "../../ResumeModal/ResumeModal";
import { LocalStorageEnum } from "../../../infrastructure/constants/LocalStorageEnum";

const useStyles = makeStyles((theme) => ({
  resellerMain: {
    height: `calc(100% - ${theme.spacing(8.75)}px)`,
    width: "100% !important",
    overflow: "hidden !important",
    display: "flex !important",

    [theme.breakpoints.down("sm")]: {
      height: `calc(100% - ${theme.spacing(18.75)}px) !important`,
    },
  },
}));
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
export const MainContainer: FC = () => {
  const location = useLocation();
  const classes = useStyles();
  const query = useQuery();
  const { isMobile } = useConsoleLayoutContext();
  const [openSideBar, setOpenSideBar] = useState<boolean>(true);
  const [showTabModal, setShowTableModal] = useState<boolean>(false);
  const [showSidebar, setShowSidebar] = useState<boolean>(true);
  const [merchantResume, setMerchantResume] = useState<MerchantResume>(
    {} as MerchantResume
  );

  useEffect(() => {
    let hideSideBar: string | null = query.get("hideSideBar");
    if (!hideSideBar) {
      if (
        window.location.pathname.startsWith("/create-merchant-reseller") &&
        !window.location.pathname.includes("/credentials")
      ) {
        hideSideBar = "true";
      }
    }
    setShowSidebar(!(!!hideSideBar && hideSideBar === "true"));

    const createdMerchant = JSON.parse(
      localStorage.getItem(LocalStorageEnum.MERCHANT_RESUME) as string
    ) as MerchantResume;
    setMerchantResume(createdMerchant);
    const invalidPath = InvalidTabModalPaths.find(
      (path) => window.location.pathname === path
    );
    const partialInvalidPath = PartialInvalidTabModalPaths.find((path) =>
      window.location.pathname.startsWith(path)
    );

    setShowTableModal(
      hideSideBar === "true" && !invalidPath && !partialInvalidPath
    );
    if (window.location.pathname.startsWith("/create-merchant-reseller")) {
      setOpenSideBar(false);
    }
  }, [location, openSideBar]);

  useEffect(() => {
    window.onstorage = () => {
      const toggleSidebar = window.localStorage.getItem(
        LocalStorageEnum.TOGGLE_SIDEBAR
      );

      toggleSidebar === "true" ? setOpenSideBar(true) : setOpenSideBar(false);
    };
    return () => {
      delete window.onstorage;
    };
  }, []);

  return (
    <main className={classes.resellerMain}>
      {!isMobile && showSidebar && (
        <SideBar open={openSideBar} setOpen={setOpenSideBar} />
      )}
      <SectionSpa openSideBar={openSideBar} />
      {showTabModal && <ResumeModal merchantResume={merchantResume} />}
    </main>
  );
};

export default MainContainer;
