import React, { FC } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { useSnackBarAlertState } from "./state/useSnackBarAlertState";
import { SnackbarAlertProps } from "./SnackBarAlert.interfaces";
import SnackbarIcon from "./SnackbarIcon/SnackbarIcons";
import { useStyles } from "./Snackbar.style";

export const SnackBarAlert: FC<SnackbarAlertProps> = (
  props: SnackbarAlertProps
) => {
  const { open } = useSnackBarAlertState(props);
  const classes = useStyles();

  const getCustomStyle = () => {
    return props.type === "success"
      ? classes.snackbarSuccess
      : classes.snackbarError;
  };

  return (
    <Snackbar
      anchorOrigin={{
        horizontal: "center",
        vertical: "top",
      }}
      open={open}
      autoHideDuration={4000}
      onClose={props.handlerClose}
    >
      <MuiAlert
        elevation={6}
        variant="filled"
        icon={<SnackbarIcon severity={props.type} />}
        onClick={props.handlerClose}
        severity={props.type}
        className={`${classes.snackbar} ${getCustomStyle()}`}
      >
        {props.message}
      </MuiAlert>
    </Snackbar>
  );
};
