import React, { FC } from "react";
import CheckIcon from "../../../../assets/CheckIcon";
import CloseIcon from "../../../../assets/CloseIcon";
import { SnackbarIconProps } from "./SnackbarIcon.interfaces";

const SnackbarIcon: FC<SnackbarIconProps> = ({
  severity,
}: SnackbarIconProps) => {
  const icons = [
    {
      color: "success",
      icon: CheckIcon,
    },
    {
      color: "error",
      icon: CloseIcon,
    },
  ];

  for (let index = 0; index < icons.length; index++) {
    if (icons[index].color === severity) {
      const Icon = icons[index].icon;
      return <Icon data-testid={severity} />;
    }
  }

  return <></>;
};

export default SnackbarIcon;
