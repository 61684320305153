import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { registerApplication, start } from "single-spa";
import {
  ThemeProvider,
  StylesProvider,
  createGenerateClassName,
} from "@material-ui/core/styles";
import theme from "./theme";
import "zone.js";
import { appsToRegister } from "./shared/appsTo";
import { injectableScripts } from "./infrastructure/constants/InjectableScripts";
import { setScript } from "./shared/scripts";

const generateClassName = createGenerateClassName({
  productionPrefix: "mr",
});

ReactDOM.render(
  <React.StrictMode>
    <StylesProvider generateClassName={generateClassName}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </StylesProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

injectableScripts.forEach((injectableScript) => {
  setScript(injectableScript);
});

appsToRegister.forEach((app) => {
  registerApplication(app);
});

start();
