import { appsToRender } from "../../../../shared/appsTo";
import React, { FC } from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  resellerSection: {
    width: `calc(100% - ${theme.spacing(9)}px)`,
    padding: `${theme.spacing(6)}px !important`,
    height: "100% !important",
    overflowY: "auto !important" as any,
    transition: `${theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    })} !important`,

    "&.sidebarOpen": {
      width: `calc(100% - ${theme.spacing(30)}px)`,
    },

    "&>div": {
      maxHeight: "100% !important",
    },

    [theme.breakpoints.down("sm")]: {
      transition: "none !important",
      width: "100% !important",
    },
  },
}));

export interface ISectionSpaProps {
  openSideBar: boolean;
}

export const SectionSpa: FC<ISectionSpaProps> = ({
  openSideBar,
}: ISectionSpaProps) => {
  const classes = useStyles();

  return (
    <section
      className={`${classes.resellerSection}${
        openSideBar ? " sidebarOpen" : ""
      }`}
    >
      {appsToRender.map((path, index) => (
        <div key={index} id={`single-spa-application:${path}`} />
      ))}
    </section>
  );
};

export default SectionSpa;
