import { IInjectableScripts } from "../infrastructure/constants/InjectableScripts";
import { isEmpty } from "lodash";
import { environment } from "../environments/environment";

export const setScript = ({
  script,
  type,
  productionOnly,
}: IInjectableScripts): void => {
  const SCRIPT: HTMLElement = document.createElement("SCRIPT");

  SCRIPT.appendChild(document.createTextNode(script));

  //@ts-ignore
  if (!isEmpty(type)) SCRIPT.type = type;

  if (!productionOnly || environment.envName === "primary")
    document.head.appendChild(SCRIPT);
};
