import {
  MENU_ADMIN,
  MENU_USER_EMISSION,
  MENU_USER_MASTER,
  MENU_USER_READER,
} from "./KushkiMenu";
import { IKushkiRol } from "../interfaces/IKushkiRol";
import { RoutesEnum } from "./RoutesEnum";

export enum RolesEnum {
  CONSOLE_RESELLER_ADMIN = "ConsoleResellerAdmin",
  CONSOLE_RESELLER_USER_MASTER = "ConsoleResellerUserMaster",
  CONSOLE_RESELLER_USER_READER = "ConsoleResellerUserReader",
  CONSOLE_RESELLER_USER_EMISSION = "ConsoleResellerUserEmission",
}

export const ROLES: string[] = Object.values(RolesEnum);

export const ROL_CONSOLE_RESELLER_ADMIN: IKushkiRol = {
  cognito_group: RolesEnum.CONSOLE_RESELLER_ADMIN,
  menu: MENU_ADMIN,
  additional_routes: [
    RoutesEnum.WEBHOOK,
    RoutesEnum.CREATE_MERCHANT,
    RoutesEnum.TRANSACTION_RATES,
    RoutesEnum.COST_CENTER,
    RoutesEnum.EMISSION,
  ],
};

export const ROL_CONSOLE_RESELLER_USER_MASTER: IKushkiRol = {
  cognito_group: RolesEnum.CONSOLE_RESELLER_USER_MASTER,
  menu: MENU_USER_MASTER,
  additional_routes: [
    RoutesEnum.WEBHOOK,
    RoutesEnum.CREDENTIALS,
    RoutesEnum.TRANSACTION_RATES,
    RoutesEnum.CREATE_MERCHANT,
  ],
};

export const ROL_CONSOLE_RESELLER_USER_READER: IKushkiRol = {
  cognito_group: RolesEnum.CONSOLE_RESELLER_USER_READER,
  menu: MENU_USER_READER,
};

export const ROL_CONSOLE_RESELLER_USER_EMISSION: IKushkiRol = {
  cognito_group: RolesEnum.CONSOLE_RESELLER_USER_EMISSION,
  menu: MENU_USER_EMISSION,
};

export const SYSTEM_ROLES: IKushkiRol[] = [
  ROL_CONSOLE_RESELLER_ADMIN,
  ROL_CONSOLE_RESELLER_USER_MASTER,
  ROL_CONSOLE_RESELLER_USER_READER,
  ROL_CONSOLE_RESELLER_USER_EMISSION,
];
