import React, { Dispatch, FC, SetStateAction } from "react";
import { IconButton, List, ListItem, makeStyles } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItemRoute from "../../../commons/ListItemRoute/ListItemRoute";
import { useStylesListItemRoute } from "../../../commons/ListItemRoute/ListItemRouteStyles";
import { useConsoleLayoutContext } from "../../context/useConsoleLayoutContext";
import { isArray, orderBy } from "lodash";

const useStyles = makeStyles((theme) => ({
  resellerMainSidebar: {
    height: "100% !important",
    overflow: "auto !important",
    width: `${theme.spacing(9)}px !important`,
    padding: `${theme.spacing(3, 0, 3, 4)} !important`,
    transition: `${theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    })} !important`,

    "&.active": {
      width: `${theme.spacing(30)}px !important`,
    },

    "&.minimize": {
      width: `${theme.spacing(10)}px !important`,
    },
  },
}));

export interface ISideBarProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export const SideBar: FC<ISideBarProps> = ({
  open,
  setOpen,
}: ISideBarProps) => {
  const classes = useStyles();
  const classesListItemRoute = useStylesListItemRoute();
  const { kushkiMenu } = useConsoleLayoutContext();

  return (
    <aside
      className={
        open
          ? `${classes.resellerMainSidebar} active`
          : `${classes.resellerMainSidebar} minimize`
      }
    >
      <List>
        <ListItem className={classesListItemRoute.resellerSidebarListItem}>
          <IconButton
            className={classesListItemRoute.sidebarToggleButton}
            onClick={() => setOpen((prevState) => !prevState)}
          >
            {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </ListItem>

        {orderBy(kushkiMenu, "priority", "asc").map((item, index) => (
          <ListItemRoute
            key={index}
            item={item}
            showLabel={open}
            handlerClickItem={() => {
              if (isArray(item.routes)) setOpen(true);
            }}
          />
        ))}
      </List>
    </aside>
  );
};

export default SideBar;
