import { Box, Divider, Drawer, List, makeStyles } from "@material-ui/core";
import { orderBy } from "lodash";
import ListItemRoute from "../../../commons/ListItemRoute/ListItemRoute";
import React, { FC } from "react";
import { useConsoleLayoutContext } from "../../context/useConsoleLayoutContext";
import DropdownRolMenu from "./DropdownRolMenu/DropdownRolMenu";
import HeaderMenu from "./HeaderMenu/HeaderMenu";

const useStyles = makeStyles((theme) => ({
  resellerDrawerMenuContainer: {
    width: `${theme.spacing(38)}px !important`,
    height: "100% !important",
  },
  resellerDrawerMenu: {
    width: "100% !important",
    padding: `${theme.spacing(0, 2)} !important`,
    height: `calc(100% - ${theme.spacing(10)}px)`,
  },
  marginTop: {
    marginTop: `${theme.spacing(1)}px !important`,
  },
}));

export interface IRightMenuProps {
  openMenu: boolean;
  onClose: () => void;
}

export const RightMenu: FC<IRightMenuProps> = ({
  openMenu,
  onClose,
}: IRightMenuProps) => {
  const { kushkiMenu } = useConsoleLayoutContext();
  const classes = useStyles();

  return (
    <Drawer anchor="right" open={openMenu} onClose={() => onClose()}>
      <Box className={classes.resellerDrawerMenuContainer}>
        <HeaderMenu handlerOnClick={onClose} />

        <Box className={classes.resellerDrawerMenu}>
          <List>
            <DropdownRolMenu />

            <Divider className={classes.marginTop} />

            {orderBy(kushkiMenu, "priority", "asc").map((item, index) => (
              <ListItemRoute
                key={index}
                item={item}
                handlerSelectRoute={onClose}
              />
            ))}
          </List>
        </Box>
      </Box>
    </Drawer>
  );
};

export default RightMenu;
