/* eslint-disable */
export const environment: {
    kushkiUrl: string;
    envName: string;
    domain: string;
    devSpa: string;
    devTools: boolean;
} = {
    kushkiUrl: "https://api-reseller.kushkipagos.com",
    envName: "primary",
    domain: "console-reseller.kushkipagos.com",
    devSpa: "",
    devTools: false
};

