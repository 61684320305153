import React from "react";
import { CardBlueProps, CardBlue } from "../CardBlue/CardBlue";
import { get, defaultTo } from "lodash";
import { LANGUAGE_CATALOG } from "../../../infrastructure/constants/LanguageCatalog";
import { createStyles, Grid, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  CurrencyByCountry,
  CurrencyEnum,
} from "../../../infrastructure/constants/CurrencyEnum";

export interface RatesTabProps {
  rates?: any;
  country?: string;
}

const formatNumber = (value: number | string, currency: string) => {
  if (typeof value === "number") {
    return value.toFixed(2) + " " + currency;
  }
  return value;
};

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    title: {
      fontSize: "16px !important",
    },
    paper: {
      boxShadow: "none !important",
      borderRadius: "5px !important",
      backgroundColor: "#F7FAFC !important",
      marginBottom: "20px !important",
    },
    certificateStyle: {
      borderRadius: 5,
      backgroundColor: "#E2E8F0",
      marginRight: 6,
      paddingTop: 2,
      paddingBottom: 1,
      paddingLeft: 20,
      paddingRight: 20,
    },
  })
);

export const RatesTab: React.FC<RatesTabProps> = (props: RatesTabProps) => {
  const classes = useStyles();
  const { rates } = props;
  const country = get(props, "country", "");
  const currency = CurrencyByCountry[country] || CurrencyEnum.DEFAULT;

  const ratesClone = defaultTo(rates, []).slice(0, 5);

  const pratesCards: CardBlueProps[] = ratesClone.map((rate: any) => {
    const configurations = get(rate, "configuration", []);

    const configurationsItems: any = [];
    configurations.map((config: any) => {
      configurationsItems.push({
        key: "Rango de transacciones",
        value: `Desde ${get(config, "greaterThan", "")} transacciones`,
      });
      configurationsItems.push({
        key: "Monto fijo",
        value: formatNumber(get(config, "fixedAmount", ""), currency),
        value2: get(config, "initialRate", ""),
      });
    });
    return {
      title: get(rate, "alias", ""),
      items: [
        {
          isTitle: true,
          key: "title1",
          value: get(rate, "alias", ""),
        },
        {
          isIcon: true,
          key: "certificateAlias",
          value: get(rate, "certificateId", ""),
        },
        {
          key: "Alias",
          value: get(rate, "alias", ""),
        },
        ...configurationsItems,
      ],
    } as CardBlueProps;
  });

  return (
    <>
      <Paper className={classes.paper}>
        <Grid
          container
          spacing={2}
          color="secondary"
          style={{
            paddingTop: 10,
            paddingLeft: 20,
            paddingRight: 20,
            paddingBottom: 15,
          }}
        >
          {pratesCards.length > 0 &&
            pratesCards.map((prop: CardBlueProps, index) => (
              <>
                {prop.items.map((item, index) => (
                  <>
                    {item.isTitle && (
                      <Grid
                        item
                        xs={12}
                        spacing={2}
                        style={{ paddingBottom: 1 }}
                      >
                        <Typography
                          component={"div"}
                          variant="h5"
                          color="textPrimary"
                          className={classes.title}
                        >
                          {item.value}
                        </Typography>
                      </Grid>
                    )}
                    {item.isIcon && (
                      <Grid
                        item
                        xs={12}
                        spacing={2}
                        style={{ paddingBottom: 1 }}
                      >
                        <span
                          key={item.key}
                          id={item.key}
                          className={classes.certificateStyle}
                        >
                          {item.value}
                        </span>
                      </Grid>
                    )}

                    {!item.isTitle && !item.isIcon && (
                      <>
                        <Grid
                          item
                          xs={5}
                          spacing={2}
                          style={{ paddingBottom: 0 }}
                          key={"item-grid-" + item.key}
                        >
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            key={"item-typo-" + item.key}
                          >
                            {item.key}:
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          container
                          xs={7}
                          spacing={2}
                          style={{ paddingBottom: 0 }}
                          key={"item-grid-2-" + item.key}
                        >
                          <Grid item>
                            <Typography
                              variant="body2"
                              key={"item-typo-2-" + item.key}
                            >
                              {item.value}
                            </Typography>
                          </Grid>
                          <Grid item>
                            {item.value2 && (
                              <Typography
                                variant={"body2"}
                                style={{
                                  background: "#E0FFF8",
                                  paddingLeft: 20,
                                  paddingRight: 20,
                                }}
                              >
                                {"Tarifa Inicial"}
                              </Typography>
                            )}
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </>
                ))}
              </>
            ))}
        </Grid>
      </Paper>
    </>
  );
};
