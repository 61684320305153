import React, { FC } from "react";
import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  roleNotFound: {
    width: "100% !important",
    height: "100% !important",
    display: "flex !important",
    justifyContent: "center !important",
    alignItems: "center !important",
  },
}));

export const RoleNotFoundPage: FC = () => {
  const classes = useStyles();

  return (
    <main className={classes.roleNotFound}>
      <Typography color={"primary"} variant={"h1"}>
        Oops! Usuario sin rutas disponibles
      </Typography>
    </main>
  );
};

export default RoleNotFoundPage;
