import React, {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import { useMediaQuery } from "@material-ui/core";
import theme from "../../../theme";
import { environment } from "../../../environments/environment";
import { defaultTo, isObject } from "lodash";
import { useLocation } from "react-router-dom";
import { IKushkiMenu } from "../../../infrastructure/interfaces/IKushkiMenu";
import { IMerchantForm } from "../../../infrastructure/interfaces/IMerchantForm";
import { RoleService } from "../../../services/RoleService";
import { IRoleService } from "../../../infrastructure/interfaces/IRoleService";
import { ZoneCountry } from "../../../infrastructure/constants/ZoneCountry";

export interface IConsoleLayoutContext {
  isMobile: boolean;
  isProduction: boolean;
  merchantInfo: Partial<IMerchantForm>;
  activePath: string;
  activeRoute: string;
  handlers: THandler;
}

export interface ISharedAppProps {
  appStarted: boolean;
  isAdmin: boolean;
  fullName: string;
  initialsName: string;
  kushkiMenu: IKushkiMenu[];
  isValidRole: boolean;
}

export interface ISharedAppHandlers {
  logout: () => void;
  isAllowedRouteByRol: (activeRoute: string) => boolean;
}

export type TAppData = ISharedAppProps & ISharedAppHandlers;
export type THandler = ISharedAppHandlers;
export type TConsoleLayoutContext = IConsoleLayoutContext & ISharedAppProps;

const ConsoleLayoutContext = createContext<TConsoleLayoutContext>(undefined!);

export const ConsoleLayoutProvider: FC = ({
  children,
}: PropsWithChildren<any>) => {
  const isMobile: boolean = useMediaQuery(theme.breakpoints.down("sm"));
  const isProduction: boolean = environment.envName === "primary";
  const [merchantInfo, setMerhchantInfo] = useState<Partial<IMerchantForm>>({});
  const [appServiceByRol, setAppServiceByRol] = useState<IRoleService>();
  const [appData, setAppData] = useState<TAppData>({
    appStarted: false,
    initialsName: "",
    fullName: "",
    isAdmin: false,
    kushkiMenu: [],
    isValidRole: false,
    logout: () => {},
    isAllowedRouteByRol: () => false,
  });
  const activeRoute: string = useLocation().pathname;

  const activePath: string = RoleService.getPath(activeRoute);

  const {
    appStarted,
    initialsName,
    fullName,
    isAdmin,
    kushkiMenu,
    isValidRole,
    logout,
    isAllowedRouteByRol,
  } = appData;

  useEffect(() => {
    setAppServiceByRol(new RoleService());
  }, []);

  useEffect(() => {
    if (isObject(appServiceByRol)) {
      if (appServiceByRol.isJWTExpired()) {
        appServiceByRol.logout();
        return;
      }

      const timeOut: NodeJS.Timeout = setTimeout(
        () => appServiceByRol.logout(),
        appServiceByRol.getJWTLeftTime()
      );

      const currentIsAdmin: boolean = appServiceByRol.isAdmin();
      const currentIsValidRole: boolean = appServiceByRol.isValidRole();

      setAppData({
        appStarted: true,
        initialsName: appServiceByRol.getInitialsName(),
        fullName: appServiceByRol.getFullName(),
        isAdmin: currentIsAdmin,
        kushkiMenu: appServiceByRol.getMenu(),
        logout: () => appServiceByRol.logout(),
        isAllowedRouteByRol: (currentRoute: string) =>
          appServiceByRol.isAllowedRouteByRol(currentRoute),
        isValidRole: currentIsValidRole,
      });

      if (currentIsValidRole) {
        appServiceByRol.setSystemTimeZone(ZoneCountry.Ecuador);

        appServiceByRol.initLocalStorage();
      }

      return () => {
        clearTimeout(timeOut);
      };
    }
  }, [appServiceByRol]);

  const value: TConsoleLayoutContext = {
    appStarted,
    isMobile,
    isProduction,
    merchantInfo,
    isAdmin,
    fullName,
    initialsName,
    kushkiMenu,
    isValidRole,
    activePath,
    activeRoute,
    handlers: {
      logout,
      isAllowedRouteByRol,
    },
  };

  return (
    <ConsoleLayoutContext.Provider value={value}>
      {children}
    </ConsoleLayoutContext.Provider>
  );
};

export const useConsoleLayoutContext = () => {
  return useContext(ConsoleLayoutContext);
};
