import { makeStyles } from "@material-ui/core";

export const useStylesListItemRoute = makeStyles((theme) => ({
  resellerSidebarListItem: {
    padding: `${theme.spacing(1, 2, 1, 0)} !important`,
    minHeight: `${theme.spacing(6.75)}px !important`,
  },
  resellerSidebarListIcon: {
    justifyContent: "center !important",
    minWidth: `${theme.spacing(5)}px !important`,
  },
  resellerEmissionAndHistoricIcon: {
    maxWidth: `${theme.spacing(2.5)}px !important`,
    maxHeight: `${theme.spacing(2.5)}px !important`,
    width: `${theme.spacing(2.5)}px !important`,
    height: "auto !important",
    stroke: "currentcolor !important",

    "&.active": {
      stroke: `${theme.palette.primary.main} !important`,
    },
  },
  resellerSidebarIcon: {
    maxWidth: `${theme.spacing(2.5)}px !important`,
    maxHeight: `${theme.spacing(2.5)}px !important`,
    width: `${theme.spacing(2.5)}px !important`,
    height: "auto !important",
    fill: "currentcolor !important",
    color: "#6D7781 !important",

    "&.active": {
      color: `${theme.palette.primary.main} !important`,
    },
  },
  resellerSidebarText: {
    color: "#6D7781 !important",
    marginBottom: "0px !important",
    paddingLeft: `${theme.spacing(0.5)}px !important`,

    "&.active": {
      color: `${theme.palette.primary.main} !important`,
    },
  },
  resellerSidebarSpan: {
    fontSize: `${theme.spacing(1.875)}px !important`,
  },
  sidebarToggleButton: {
    width: `${theme.spacing(5)}px !important`,
    height: `${theme.spacing(5)}px !important`,
    backgroundColor: "#CBD5E0 !important",
    borderRadius: `${theme.spacing(0, 0.5, 0.5, 0)} !important`,
  },
  resellerSidebarNested: {
    padding: `${theme.spacing(1, 2, 1, 5)} !important`,
    color: "#6D7781 !important",

    "&.active": {
      color: `${theme.palette.primary.main} !important`,
    },
  },
}));
