import { RoutesEnum } from "./RoutesEnum";

interface IApplication {
  name: string;
  route: string[];
}

export const applications: IApplication[] = [
  {
    name: "login",
    route: [RoutesEnum.AUTH],
  },
  {
    name: "dashboard",
    route: [RoutesEnum.DASHBOARD],
  },
  {
    name: "transaction",
    route: [RoutesEnum.TRANSACTIONS],
  },
  {
    name: "wallet",
    route: [RoutesEnum.WALLET],
  },
  {
    name: "merchant",
    route: [RoutesEnum.MERCHANT],
  },
  {
    name: "billing",
    route: [RoutesEnum.BILLING],
  },
  {
    name: "information",
    route: [RoutesEnum.INFORMATION],
  },
  {
    name: "user",
    route: [RoutesEnum.USERS],
  },
  {
    name: "merchant-billing",
    route: [RoutesEnum.MERCHANT_BILLING],
  },
  {
    name: "webhook",
    route: [RoutesEnum.WEBHOOK],
  },
  {
    name: "credentials",
    route: [RoutesEnum.CREDENTIALS],
  },
  {
    route: [RoutesEnum.DEVS],
    name: "devs",
  },
  {
    name: "create-merchant",
    route: [RoutesEnum.CREATE_MERCHANT],
  },
  {
    name: "merchant-list",
    route: [RoutesEnum.MERCHANTS],
  },
  {
    name: "transaction-rates",
    route: [RoutesEnum.TRANSACTION_RATES],
  },
  {
    name: "emission",
    route: [RoutesEnum.EMISSION, RoutesEnum.OLD_EMISSION],
  },
  {
    name: "cost-center",
    route: [RoutesEnum.COST_CENTER],
  },
];
