import React from "react";
import { createStyles, Grid, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

export interface CertificateProps {
  title: string;
  items: CertificateItem[];
}

interface CertificateItem {
  certificateId: string;
  certificateName: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      boxShadow: "none !important",
      borderRadius: "5px !important",
      backgroundColor: "#F7FAFC !important",
      marginBottom: "20px !important",
    },
    title: {
      fontSize: "16px !important",
    },
    certificateStyle: {
      borderRadius: 5,
      backgroundColor: "#E2E8F0",
      marginRight: 6,
      paddingTop: 5,
      paddingBottom: 5,
      paddingLeft: 20,
      paddingRight: 20,
    },
  })
);

export const Certificates: React.FC<CertificateProps> = (
  props: CertificateProps
) => {
  const classes = useStyles();
  return (
    <>
      <Paper className={classes.paper}>
        <Grid
          container
          spacing={2}
          color="secondary"
          style={{
            paddingTop: 10,
            paddingLeft: 20,
            paddingRight: 20,
            paddingBottom: 15,
          }}
        >
          <Grid item xs={12} spacing={2} style={{ paddingBottom: 20 }}>
            <Typography variant="h5" className={classes.title}>
              {props.title}
            </Typography>
          </Grid>
          <Grid item xs={12} spacing={2} style={{ paddingTop: 5 }}>
            {props.items.map((item, index) => (
              <span
                key={item.certificateId}
                id={item.certificateId}
                className={classes.certificateStyle}
              >
                {item.certificateId}
              </span>
            ))}
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};
