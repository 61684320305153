import { navigateToUrl } from "single-spa";
import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import React, { FC, SVGProps, useEffect, useState } from "react";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { useStylesListItemRoute } from "../ListItemRouteStyles";
import { useConsoleLayoutContext } from "../../../ConsoleLayout/context/useConsoleLayoutContext";
import { IItemMenu } from "../../../../infrastructure/interfaces/IKushkiMenu";

export interface IMultiRouteItemProps {
  title: string;
  routes: IItemMenu[];
  icon: FC<SVGProps<SVGSVGElement>>;
  showLabel: boolean;
  handlerSelectRoute: () => void;
  handlerClickItem: () => void;
}

export const MultiRouteItem: FC<IMultiRouteItemProps> = ({
  title,
  routes,
  icon: Icon,
  showLabel,
  handlerClickItem,
  handlerSelectRoute,
}: IMultiRouteItemProps) => {
  const classes = useStylesListItemRoute();
  const [open, setOpen] = useState<boolean>(false);
  const { activeRoute } = useConsoleLayoutContext();
  const possibleRoutes: string[] = routes.map(({ route }) => route);

  useEffect(() => {
    if (!showLabel) {
      setOpen(false);
    }
  }, [showLabel]);

  return (
    <>
      <ListItem
        button
        className={classes.resellerSidebarListItem}
        onClick={() => {
          handlerClickItem();
          setOpen(!showLabel ? true : (prevState) => !prevState);
        }}
      >
        <ListItemIcon className={classes.resellerSidebarListIcon}>
          <Icon
            className={`${classes.resellerSidebarIcon}${
              possibleRoutes.includes(activeRoute) ? " active" : ""
            }`}
          />
        </ListItemIcon>

        <ListItemText
          className={`${classes.resellerSidebarText}${
            possibleRoutes.includes(activeRoute) ? " active" : ""
          }`}
          classes={{ primary: classes.resellerSidebarSpan }}
          hidden={!showLabel}
          primary={title}
        />

        {showLabel &&
          (open ? (
            <ExpandLess className={classes.resellerSidebarText} />
          ) : (
            <ExpandMore className={classes.resellerSidebarText} />
          ))}
      </ListItem>

      {showLabel && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {routes.map(({ title: childrenTitle, route }, index) => (
              <ListItem
                key={index}
                button
                className={classes.resellerSidebarNested}
                onClick={() => {
                  handlerSelectRoute();
                  navigateToUrl(route);
                }}
              >
                <ListItemText
                  className={`${classes.resellerSidebarText}${
                    activeRoute === route ? " active" : ""
                  }`}
                  classes={{ primary: classes.resellerSidebarSpan }}
                  primary={childrenTitle}
                />
              </ListItem>
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
};

export default MultiRouteItem;
