import React, { FC } from "react";
import { makeStyles } from "@material-ui/core";
import RightHeaderContainer from "./RightHeaderContainer/RIghtHeaderContainer";
import LeftHeaderContainer from "./LeftHeaderContainer/LeftHeaderContainer";
import { useConsoleLayoutContext } from "../context/useConsoleLayoutContext";

const useStyles = makeStyles((theme) => ({
  consoleHeader: {
    width: "100% !important",
    height: `${theme.spacing(8.75)}px !important`,
    backgroundColor: "#FFFFFF !important",
  },
  headerTopBar: {
    background:
      "linear-gradient(268.59deg, #0093A2 -1.58%, #00E6B2 100.09%) !important",
    width: "100% !important",
    height: `${theme.spacing(0.75)}px !important`,
  },
  headerContainer: {
    display: "flex !important",
    width: "100% !important",
    height: `calc(100% - ${theme.spacing(0.75)}px) !important`,
    justifyContent: "space-between !important",
    alignItems: "center !important",
  },
}));

export const Header: FC = () => {
  const classes = useStyles();
  const { isMobile } = useConsoleLayoutContext();

  return (
    <header className={classes.consoleHeader}>
      <div className={classes.headerTopBar} />
      <div className={classes.headerContainer}>
        <LeftHeaderContainer />
        {!isMobile && <RightHeaderContainer />}
      </div>
    </header>
  );
};

export default Header;
