import React, { FC } from "react";
import { RoutesEnum } from "../../infrastructure/constants/RoutesEnum";
import ConsoleLayout from "../../components/ConsoleLayout/ConsoleLayout";
import { makeStyles } from "@material-ui/core";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { allowedRoutes, suffixPath } from "../../shared/appsTo";
import Preconfig from "../../infrastructure/constants/Preconfig";
import { ConsoleLayoutProvider } from "../../components/ConsoleLayout/context/useConsoleLayoutContext";
import RoleNotFoundPage from "../../components/RoleNotFoundPage/RoleNotFoundPage";
const { prefixPath } = Preconfig;

const useStyles = makeStyles(() => ({
  mainReseller: {
    width: "100%",
    height: "100%",

    "&>div": {
      width: "100%",
      height: "100%",
    },
  },
}));

const MainContent: FC = () => {
  const classes = useStyles();

  return (
    <BrowserRouter>
      <Switch>
        <Route path={allowedRoutes}>
          <ConsoleLayoutProvider>
            <ConsoleLayout />
          </ConsoleLayoutProvider>
        </Route>

        <Route path={RoutesEnum.AUTH}>
          <main className={classes.mainReseller}>
            <div
              id={`single-spa-application:@kushki/${prefixPath}-login${suffixPath}`}
            />
          </main>
        </Route>

        <Route path={RoutesEnum.ROLE_NOT_FOUND}>
          <RoleNotFoundPage />
        </Route>

        <Route>
          <Redirect to={RoutesEnum.AUTH} />
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

export default MainContent;
