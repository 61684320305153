import { makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) => ({
  snackbar: {
    cursor: "pointer",
    paddingRight: "112px",
    color: "white !important",
  },
  snackbarSuccess: {
    backgroundColor: `${theme.palette.success.dark} !important`,
  },
  snackbarError: {
    backgroundColor: `${theme.palette.error.dark} !important`,
    color: "white !important",
  },
}));
