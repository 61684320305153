import { RoutesEnum } from "../infrastructure/constants/RoutesEnum";
import {
  assign,
  concat,
  defaultTo,
  get,
  intersection,
  isEmpty,
  isString,
  union,
  uniq,
} from "lodash";
import { LocalStorageEnum } from "../infrastructure/constants/LocalStorageEnum";
import { IPayload } from "../infrastructure/interfaces/IPayload";
import {
  ROLES,
  RolesEnum,
  SYSTEM_ROLES,
} from "../infrastructure/constants/Roles";
import { IKushkiMenu } from "../infrastructure/interfaces/IKushkiMenu";
import { environment } from "../environments/environment";
import { IMerchantForm } from "../infrastructure/interfaces/IMerchantForm";
import { AxiosResponse } from "axios";
import axios from "../shared/axios-util";
import { ApiRoute } from "../infrastructure/constants/InternalApiEnum";
import { IRoleService } from "../infrastructure/interfaces/IRoleService";
import { ZoneCountry } from "../infrastructure/constants/ZoneCountry";
import { IKushkiRol } from "../infrastructure/interfaces/IKushkiRol";

export interface ILocalStorageItem {
  item: string;
  data: any;
}

export interface IRolesObject {
  [k: string]: boolean;
}

export interface ITime {
  current: number;
  expire: number;
}

export class RoleService implements IRoleService {
  private readonly _payload: IPayload;
  private readonly _roles: string[];
  private readonly _menu: IKushkiMenu[];
  private readonly _adminMode: boolean;
  private readonly _allowedRoutes: string[];
  private readonly _merchantId: string;
  private readonly _localMerchantInfo: IMerchantForm;
  private readonly _name: string;
  private readonly _lastName: string;
  private readonly _fullName: string;
  private readonly _initialsName: string;
  private readonly _time: ITime;
  private readonly _isValidRole: boolean;
  private readonly _additionalRoutes: RoutesEnum[];
  private readonly _kushkiRole: IKushkiRol[];

  constructor() {
    this._payload = this._getPayload();
    this._roles = this._getRoles();
    this._kushkiRole = this._getKushkiRole();
    this._additionalRoutes = this._getAdditionalRoutes();
    this._menu = this._getMenu();
    this._adminMode = this._isAdmin();
    this._allowedRoutes = this._getAllowedRoutes();
    this._merchantId = this._getMerchantId();
    this._localMerchantInfo = this._getLocalMerchantInfo();
    this._name = this._getName();
    this._lastName = this._getLastName();
    this._fullName = this._getFullName();
    this._initialsName = this._getInitialsName();
    this._time = this._getTime();
    this._isValidRole = this._getIsValidRole();
  }

  static getPath(route: string): string {
    return defaultTo(route.match(/^\/[^\/]+/), [""])[0];
  }

  public logout(): void {
    localStorage.clear();
    window.location.href = RoutesEnum.AUTH;
  }

  public isAdmin(): boolean {
    return this._adminMode;
  }

  public getMenu(): IKushkiMenu[] {
    return this._menu;
  }

  public isAllowedRouteByRol(activeRoute: string): boolean {
    return this._allowedRoutes.includes(activeRoute);
  }

  public getFullName(): string {
    return this._fullName;
  }

  public getInitialsName(): string {
    return this._initialsName;
  }

  public isJWTExpired(): boolean {
    const { current, expire } = this._time;

    return current > expire;
  }

  public getJWTLeftTime(): number {
    const { current, expire } = this._time;
    const diffTime: number = expire - current;

    return diffTime > 0 ? diffTime : 0;
  }

  public async getMerchantInfo(
    callback: (merchantInfo: IMerchantForm) => void
  ): Promise<void> {
    const merchantInfo: IMerchantForm = !isEmpty(this._localMerchantInfo)
      ? this._localMerchantInfo
      : await this._getMerchantInfoByRequest();

    this.setSystemTimeZone(merchantInfo.country);
    callback(merchantInfo);
  }

  public isValidRole(): boolean {
    return this._isValidRole;
  }

  public setSystemTimeZone(country: ZoneCountry): void {
    localStorage.setItem(
      LocalStorageEnum.SYSTEM_TIMEZONE,
      get(ZoneCountry, country, ZoneCountry.Ecuador)
    );
  }

  public initLocalStorage(): void {
    const itemsToLocalStorage: ILocalStorageItem[] = [
      { item: LocalStorageEnum.ROLES, data: this._getRolesObject() },
      { item: LocalStorageEnum.IS_ADMIN, data: this._adminMode },
      { item: LocalStorageEnum.EMAIL, data: this._getEmail() },
      { item: LocalStorageEnum.MERCHANT_ID, data: this._merchantId },
      { item: LocalStorageEnum.USERNAME, data: this._getUsername() },
      { item: LocalStorageEnum.FULL_NAME, data: this._fullName },
      { item: LocalStorageEnum.TIMEZONE, data: this._getTimeZone() },
      { item: LocalStorageEnum.TOGGLE_SIDEBAR, data: "true" },
    ];

    itemsToLocalStorage.forEach(({ item, data }) => {
      localStorage.setItem(item, isString(data) ? data : JSON.stringify(data));
    });
  }

  private _getIsValidRole(): boolean {
    return !isEmpty(this._menu);
  }

  private _getTime(): ITime {
    const { exp } = this._payload;

    return {
      current: new Date().getTime(),
      expire: defaultTo(exp, 0) * 1000,
    };
  }

  private _getRoles(): string[] {
    const cognitoRoles: string[] = get(this._payload, "cognito:groups", []);

    return intersection(ROLES, cognitoRoles);
  }

  private _getMerchantId(): string {
    return get(this._payload, "preferred_username", "");
  }

  private _getLocalMerchantInfo(): IMerchantForm {
    return JSON.parse(
      defaultTo(
        localStorage.getItem(LocalStorageEnum.MERCHANT_BASIC_INFORMATION),
        "{}"
      )
    );
  }

  private async _getMerchantInfoByRequest(): Promise<IMerchantForm> {
    try {
      const { data }: AxiosResponse<IMerchantForm> =
        await axios.post<IMerchantForm>(
          `${environment.kushkiUrl}${ApiRoute.MERCHANT_INFO}`,
          {
            publicMerchantId: this._merchantId,
          }
        );

      localStorage.setItem(
        LocalStorageEnum.MERCHANT_BASIC_INFORMATION,
        JSON.stringify(data)
      );

      return data;
    } catch {
      //@ts-ignore
      return Promise.resolve({});
    }
  }

  private _getMenu(): IKushkiMenu[] {
    const menus: IKushkiMenu[][] = this._kushkiRole.map((rol) => rol.menu);

    return union(...menus);
  }

  private _getAdditionalRoutes(): RoutesEnum[] {
    const kushkiRole = this._kushkiRole
      .filter((rol) => !isEmpty(rol.additional_routes))
      .map((rol) => rol.additional_routes);

    return union(...kushkiRole);
  }

  private _getKushkiRole(): IKushkiRol[] {
    return SYSTEM_ROLES.filter((rol) =>
      this._roles.includes(rol.cognito_group)
    );
  }

  private _getPayload(): IPayload {
    return JSON.parse(
      defaultTo(localStorage.getItem(LocalStorageEnum.PAYLOAD), "{}")
    );
  }

  private _isAdmin(): boolean {
    return this._roles.includes(RolesEnum.CONSOLE_RESELLER_ADMIN);
  }

  private _getRolesObject(): IRolesObject {
    const rolesObject: IRolesObject[] = ROLES.map((rol) => ({
      [rol]: this._roles.includes(rol),
    }));

    return assign(
      // @ts-ignore
      ...rolesObject
    );
  }

  private _getEmail(): string {
    return get(this._payload, "email", "");
  }

  private _getAllowedRoutes(): string[] {
    const routesByConcat: string[][] = this._menu.map(({ routes }) =>
      isString(routes)
        ? [RoleService.getPath(routes)]
        : routes.map(({ route }) => RoleService.getPath(route))
    );
    const additionalRoutesByConcat: string[][] = this._additionalRoutes.map(
      (route) => [RoleService.getPath(route)]
    );

    return uniq(
      concat(
        ...routesByConcat,
        //@ts-ignore
        ...additionalRoutesByConcat
      )
    );
  }

  private _getName(): string {
    return get(this._payload, "name", "");
  }

  private _getLastName(): string {
    return get(this._payload, "family_name", "");
  }

  private _getFullName(): string {
    return `${this._name} ${this._lastName}`;
  }

  private _getInitialsName(): string {
    return `${defaultTo(this._name[0], "")}${defaultTo(this._lastName[0], "")}`;
  }

  private _getUsername(): string {
    return get(this._payload, "cognito:username", "");
  }

  private _getTimeZone(): string {
    return get(this._payload, "zoneinfo", "UTC-05 (Bogota, Quito, Lima)");
  }
}
