import { Collapse, ListItem, makeStyles } from "@material-ui/core";
import RolMenuButton from "../../../../commons/RolMenuButton/RolMenuButton";
import LogoutListItem from "../../../../commons/LogoutListItem/LogoutListItem";
import React, { FC, useState } from "react";

const useStyles = makeStyles((theme) => ({
  resellerDropdownListItem: {
    padding: `${theme.spacing(1, 2, 1, 0)} !important`,
  },
}));

export const DropdownRolMenu: FC = () => {
  const [open, setOpen] = useState<boolean>(false);
  const classes = useStyles();

  return (
    <>
      <ListItem className={classes.resellerDropdownListItem}>
        <RolMenuButton
          handlerClick={() => setOpen((prevState) => !prevState)}
          open={open}
        />
      </ListItem>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <LogoutListItem />
      </Collapse>
    </>
  );
};

export default DropdownRolMenu;
