import BrazilFlag from "../../assets/imagen/flags/brazil.png";
import ChileFlag from "../../assets/imagen/flags/chile.png";
import ColombiaFlag from "../../assets/imagen/flags/colombia.png";
import EcuadorFlag from "../../assets/imagen/flags/ecuador.png";
import MexicoFlag from "../../assets/imagen/flags/mexico.png";
import PeruFlag from "../../assets/imagen/flags/peru.png";

export const countryFlags: { [key: string]: string } = {
  Brazil: BrazilFlag,
  Chile: ChileFlag,
  Colombia: ColombiaFlag,
  Ecuador: EcuadorFlag,
  Mexico: MexicoFlag,
  Peru: PeruFlag,
};
