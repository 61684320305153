/**
 * Currency Enumeration
 */
import BrazilFlag from "../../assets/imagen/flags/brazil.png";
import ChileFlag from "../../assets/imagen/flags/chile.png";
import ColombiaFlag from "../../assets/imagen/flags/colombia.png";
import EcuadorFlag from "../../assets/imagen/flags/ecuador.png";
import MexicoFlag from "../../assets/imagen/flags/mexico.png";
import PeruFlag from "../../assets/imagen/flags/peru.png";
import { CountriesEnum } from "./Countries";

export enum CurrencyEnum {
  Ecuador = "USD",
  Colombia = "COP",
  Peru = "PEN",
  Chile = "CLP",
  Mexico = "MXN",
  EEUU = "USD",
  DEFAULT = "USD",
}
export const CurrencyByCountry: { [key: string]: string } = {
  [CountriesEnum.ECUADOR]: CurrencyEnum.Ecuador,
  [CountriesEnum.COLOMBIA]: CurrencyEnum.Colombia,
  [CountriesEnum.PERU]: CurrencyEnum.Peru,
  [CountriesEnum.CHILE]: CurrencyEnum.Chile,
  [CountriesEnum.MEXICO]: CurrencyEnum.Mexico,
  [CountriesEnum.EEUU]: CurrencyEnum.EEUU,
};
