import {
  ListItem,
  ListItemIcon,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { LogOut } from "react-feather";
import React, { FC } from "react";
import { useConsoleLayoutContext } from "../../ConsoleLayout/context/useConsoleLayoutContext";

const useStyles = makeStyles((theme) => ({
  logoutItem: {
    padding: `${theme.spacing(1, 2)} !important`,
  },
  logoutButtonIconContainer: {
    minWidth: `${theme.spacing(4)}px !important`,
    color: "#293036 !important",
  },
  logoutButtonIcon: {
    width: "auto !important",
    height: `${theme.spacing(2)}px !important`,
  },
  logoutButtonText: {
    color: "#293036 !important",
    fontSize: `${theme.spacing(1.875)}px !important`,
  },
}));

export const LogoutListItem: FC = () => {
  const classes = useStyles();
  const {
    handlers: { logout },
  } = useConsoleLayoutContext();

  return (
    <ListItem button onClick={() => logout()} className={classes.logoutItem}>
      <ListItemIcon className={classes.logoutButtonIconContainer}>
        <LogOut className={classes.logoutButtonIcon} />
      </ListItemIcon>
      <Typography className={classes.logoutButtonText}>
        Cerrar sesión
      </Typography>
    </ListItem>
  );
};

export default LogoutListItem;
