import { SvgIcon, SvgIconProps } from "@material-ui/core";
import React, { FC } from "react";

const CloseIcon: FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 16 16" {...props}>
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2210_194704)">
        <path
          d="M1.44748 1.44531L12.5586 12.5564"
          stroke="#F7FAFC"
          strokeWidth="1.3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.5586 1.44531L1.44748 12.5564"
          stroke="#F7FAFC"
          strokeWidth="1.3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2210_194704">
          <rect
            width="13.3333"
            height="13.3333"
            fill="white"
            transform="matrix(-1 0 0 1 13.668 0.333984)"
          />
        </clipPath>
      </defs>
    </svg>
  </SvgIcon>
);

export default CloseIcon;
