import React from "react";
import MainContent from "./containers/MainContent/MainContent";
import "App.css";
import { CssBaseline } from "@material-ui/core";
import { GTMProvider } from "@elgorditosalsero/react-gtm-hook";

function App() {
  const gtmParams = { id: "GTM-5RRRSSB" };

  return (
    <>
      <GTMProvider state={gtmParams}>
        <CssBaseline />
        <MainContent />
      </GTMProvider>
    </>
  );
}

export default App;
