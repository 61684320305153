import React, { FC } from "react";
import Header from "./Header/Header";
import MainContainer from "./MainContainer/MainContainer";
import Footer from "./Footer/Footer";
import { useConsoleLayoutContext } from "./context/useConsoleLayoutContext";
import { Redirect } from "react-router-dom";
import { RoutesEnum } from "../../infrastructure/constants/RoutesEnum";
import { SnackBarAlert } from "components/commons/Snackbar/SnackBarAlert";
import { useConsoleLayout } from "./state/useConsoleLayout";

export const ConsoleLayout: FC = () => {
  const {
    appStarted,
    isMobile,
    activePath,
    isValidRole,
    handlers: { isAllowedRouteByRol },
  } = useConsoleLayoutContext();
  const { functions, values } = useConsoleLayout();
  const isAllowedRoute: boolean = isAllowedRouteByRol(activePath);
  const renderValidations = (): JSX.Element | boolean =>
    isValidRole ? (
      !isAllowedRoute && <Redirect to={RoutesEnum.AUTH} />
    ) : (
      <Redirect to={RoutesEnum.ROLE_NOT_FOUND} />
    );

  return (
    <>
      {appStarted && renderValidations()}
      <Header />
      <MainContainer />
      {isMobile && <Footer />}
      <SnackBarAlert
        handlerClose={functions.handleCloseSnackbar}
        message={values.snackbarMessage}
        open={values.snackbarIsOpen}
        type={values.snackbarType}
      />
    </>
  );
};

export default ConsoleLayout;
